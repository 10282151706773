import React, { Fragment, memo } from 'react'
import { Trade } from 'fortcake-sdk'
import { Flex, ChevronRightIcon } from 'fortcake-uikit-v2'
import { unwrappedToken } from '../../../utils/wrappedCurrency'
import { Text } from './styleds'

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
    return (
        <Flex flexWrap="wrap" width="100%" justifyContent="flex-end" alignItems="center">
            {trade.route.path.map((token, i, path) => {
                const isLastItem: boolean = i === path.length - 1
                const currency = unwrappedToken(token)
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={i}>
                        <Flex alignItems="end">
                            <Text $contrast={2} fontSize="14px" ml="0.125rem" mr="0.125rem">
                                {currency.symbol}
                            </Text>
                        </Flex>
                        {!isLastItem && <ChevronRightIcon width="12px" />}
                    </Fragment>
                )
            })}
        </Flex>
    )
})
