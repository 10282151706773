import React, { SVGAttributes } from 'react'

const SvgComponent: React.FC<SVGAttributes<HTMLOrSVGElement>> = (props) => (
    <svg
        viewBox="0 0 489.2 489.1"
        {...props}
    >
        <circle
            style={{
                fill: "#e68498",
            }}
            cx={247.1}
            cy={245.3}
            r={238.7}
        />
        <path
            style={{
                fill: "#e4e4e4",
            }}
            d="M249.1 397.4H228c-5.9-.6-11.9-1.2-17.8-1.7-.4 0-.8.5-1.2.8-1.3 0-2.7-.1-4-.1-.3-.3-.7-.6-1-.9-1-.9-1.8-2.3-2.9-2.8-5-1.9-5.5-2.7-4.1-8.1 3.4.1 3.8-2.3 4.1-5 .2-2.4.6-4.8.9-7.2 4.1-.3 8.2-.8 12.3-.9 6.9-.2 13.9 0 20.8 0 3 2.6 2 6.1 1.9 9.3 0 3 1.1 5.4 3.8 5.3 10.1-.3 20.2-1 30.3-1.6 2 .1 3.1-.6 3-2.8-.1-1.8.2-3.7-.1-5.5-.6-4.4 1.2-7.5 7-8.4 7.6-1.2 15.4-1.5 23.1-2.3 4.6 2.6 4.8 7 5 11.5.2 4.1.6 4.4 4.7 3.7 7.4-1.2 14.9-2.2 22.3-3.3 3.1-.2 4.3-1.9 4.1-5-.2-3.2 0-6.5 0-10.6 3.3.8 5.6 1.4 8.6 2.2-.9 3.5-2.5 6.6-2.3 9.5.5 6.5-3.8 8.5-8.2 10.9-1.6.9-2.7 2.5-4.1 3.8-7 1.1-13.9 2.2-20.9 3.2-.7.2-1.4.5-2.1.7-1.3.1-2.6.2-4 .3-1 .2-2 .5-3 .7-1.7.1-3.3.2-5 .3-1 .2-2 .5-3 .7l-6.9.3c-.7.2-1.4.4-2.1.7-2 .1-4 .2-6 .2-.7 0-1.4.1-2 .1h-2.8c-1 .2-2.1.5-3.1.7-2.3.1-4.7.1-7 .2-1.3 0-2.7.1-4 .1h-2.8c-3.7.5-7 .8-10.4 1z"
        />
        <path
            style={{
                fill: "#a16d78",
            }}
            d="M80.9 318.2c-.6-14.4-1.2-28.8-1.8-43.3-.1-1.3 0-2.7-.1-4-.3-8.1-.6-16.3-1-24.4 3 .4 4.2-1.6 4.8-3.9.9-4 1.5-8 2.2-12 .9 1.1 2.3 2.2 2.6 3.4 1.3 6.3 2.2 12.6 3.5 18.9.7 3.3 1.9 6.5 2.9 9.7.1.3.1.7.2 1-.4.5-1 1-1 1.6-.1 12.2-.2 24.4 0 36.6.1 5.2 1.2 10.3 1.6 15.4.3 3.7.3 7.5.2 11.3-.1 3.4-.9 6.8-.7 10.1.1 1.7 1.5 3.6 2.8 4.9 2.1 2 4.7 3.5 7 5.2-.8 0-1.8.3-2.4 0-6.2-2.9-9.6-1.1-10.4 5.6-.2 1.8-.1 3.6-.2 5.4-.7-1.3-1.4-2.6-2-3.9-.6-1.3-1.2-2.7-1.8-4.1 0-3-.2-6 0-9 .1-2.8-.8-4.8-3.2-6.3-.9-2.7-2.2-5.3-2.6-8.1-.6-3.3-.5-6.7-.6-10.1z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M334.1 134.4c7.9.5 13.8 8.2 12.1 16.2 6.8 1 11 5.4 14.1 11.2 1.3 2.9 2.5 5.9 3.8 8.8v2.9c-1.2-1.4-2.3-2.8-4-4.8v5.9c-1.1-3.3-2.7-5.8-6.9-5.2-1.2.2-2.8-.4-3.8-1.2-.7-.5-1.3-2.1-1-2.8 1.3-3.7 4.6-2.6 7.9-3.1-1.6-1.8-3.2-3.7-5.1-5.7-2.4 2.9-4.7 5.1-6.1 7.7-.6 1.1.1 4.5.5 4.6 3.8.6 3.9 4.1 5.3 6.4.1.2.4.3.6.5.3-.6.7-1.1 1.2-2.1 1.2 1.5 2.8 2.6 3 4 1.3 7.6 2.2 15.3 3.3 23-1-.1-2-.2-3-.2-.9-3.4-4.6-6.3-1.4-10.3.2-.2-.3-.9-.5-1.4-1.5-3.4-3-6.9-4.5-10.3-.2.1-.3.1-.5.2v5.3c-1.4-.8-2.9-1.6-4.2-2.5-.8-.5-1.3-1.4-1.9-2.1 2.5.2 1.4-1.5 1.4-2.6.7-.4 1.3-.8 1.7-1-1.1-1.6-2-2.9-2.8-4-1.3.9-2.7 2-4.4 3.2.9.4 1.3.6 1.7.8l.3 1.8c-.2.4-.8.9-.6 1.1 2.2 5.1 1.3 7-4.2 8.9-.4-1.8-.7-3.5-1.1-5.3-.3-1.4-.4-2.9-1.1-4.2-3.8-7.3-7.9-14.4-11.6-21.8-.7-1.5-.2-3.6-.2-5.5 6.8-3.3 9.4-9.9 12-16.4zm1.9 29.2c-2.5 3.7-2.2 7.6-1.4 12 4.1-1.9.6-4 .8-6.1.6-.6 1.4-1.3 2.1-2-.5-1.5-1-2.7-1.5-3.9 1-.7 1.8-1.4 2.7-2.2-.7-.4-1.5-.7-2.6-1.3 0 1.5-.1 2.5-.1 3.5zm2.5-5.6c1-4.3.3-7-2.7-8.9-.2 3-2.5 6.7 2.7 8.9z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M78 244.5c-.9-5.7-2.2-11.3-2.7-17-.7-7.9-1.2-15.9-1.1-23.8.1-7.6 4-13.5 10.8-17.2.5 5.8.8 6 8.1 6.2l9.9 3c.4.1.7.1 1.1.2 3.9 2.5 7.8 1.6 12 0-.3-1.5-.6-2.9-.9-4.3 1.3-.7 2.5-1.6 3.8-1.9 1.9-.5 3.9-.6 5.9-.8.2-.5.4-1 .7-1.6-2.1-2.2-4.2-4.4-6.3-6.7.6-1 1.3-2 1.9-3 4-.3 8-.7 12-1-.3 4.3-.6 8.7-.9 13-.9 3.5-1.6 6.8.6 10.2.6.9.3 2.5.4 3.8-3.3-.5-6.5-.9-9.8-1.4-3.7-.5-7.4-2-10.5 1.8-.6.7-2.2.6-3.3.6-7.2.2-13.8-1.6-19.6-5.8-4.4 2.8-7 6.6-5.7 11.5 1 4.2-.4 7.2-2.6 10.5-3.3 5.1-3.8 10.9-2.5 16.8-.6 2.3-.9 4.6-1.3 6.9zm31.2-42.4c.5.1 1 .1 1.4.2.1-1 .2-2 .3-2.9-.3-.1-.6-.1-.9-.2-.3.9-.6 1.9-.8 2.9z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M364.1 173.5v-2.9c2.2 1 2-.5 2-1.9 0-13.8 0-27.5.1-41.3 0-3.1-1.1-4.3-4.1-3.7h-23.9c.3-6.6.7-13.2 1-19.8.2-5.8.5-6.1 6.3-6.2 24-.3 48.1-.6 72.1-1 1.1 0 2.2-.4 3.3-.6.7 1.1 2.1 2.1 2.1 3.2-.1 7.9-.5 15.9-.8 24.4h-22c-6 0-6 0-6.2 6-.6 17.6-1.4 35.2-2 52.8-.1 2 .1 4 .2 6-.7.7-1.4 1.4-2.2 2.1h-20.4c-.2-.7-.3-1.3-.5-2 5.8 0 11.5.1 17.3.1 1.5 0 3.5.5 3.6-2.1.4-7.8.8-15.6 1.1-23.4.4-13 .8-25.9 1.2-38.9 0-.8.3-1.5.7-2.8h27.6V99.7c-25.9.1-51.9-1-78.3 1.4-.5 6.8-.9 13.5-1.4 20.7h27.2v56.8h-1.2c-1-1.7-1.9-3.4-2.8-5.1zM153.1 95.7c.3 1.5 1 3.1 1 4.6.1 7.1 0 14.3 0 22.3-1.5.3-3.5.9-5.5.9-11.5.1-22.9.1-34.4.1-4-.3-3.3 2-2.7 4.9h28.9c.6 2.2 1.5 3.9 1.4 5.6-.1 2.6 1.7 5.7-1.6 7.6-.2-2.3-.6-4.6-.5-6.9.1-3.5-1.5-4.5-4.9-4.4-6.9.2-13.9.1-20.8.1-1.9-.1-3.8-.2-5.5-.3v-8.7h34.4c5.4 0 6.9-1.3 7-6.7.1-4-.8-7.9-.8-11.9 0-3.5-1.9-4.4-4.7-4.3-3.6.1-7.3.7-10.9.8-10.5.2-21 .1-31.5.2H83.8c.7 17.1 1.3 33.2 1.9 47.3 7.2 5.9 12.7 10.5 18.4 15 1.6 1.3 3.5 2.3 5.2 3.5.7 3.6 2.6 7.3-1.4 10.3-1.9-2.6-4.2-5-5.7-7.9-3.8-7.4-9.7-11-18-10.7l-2.1-59.4c20.1-.4 40.2-.8 60.4-1.2 2.5 0 5 .1 7.5 0 1 .1 2-.5 3.1-.8z"
        />
        <path
            style={{
                fill: "#d4a6b1",
            }}
            d="M396.1 254.7c-.4 14.4-.7 28.8-1.1 43.1-.2 7.9-.6 15.8-1 23.7 0 2.4.1 4.8.1 7.2-.4 3.3-.8 6.6-1.1 9.8-1.9.9-4 1.7-5.7 2.9-1.6 1.1-2.9 2.7-4.3 4-1 0-1.9 0-2.9.1-.2-3.8 3.5-4.5 5.4-6.5 1.4-1.6 2.6-2.6 1.9-5-2.3-8.3-1.7-16.8-1.4-25.3.5-11.8.7-23.5 1.1-35.3.3-8.1.6-16.2 1-24.3.1-2.5.7-4.7 4-4.5.4 2.4.6 4.8 1.3 7.1.4 1.1 1.8 1.9 2.7 2.8v.2z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M130.9 385.7h-.8v-1c-1-3.1-.2-6.1 1-9 .7-.2 1.3-.5 2-.7 3.3-1.1 2-3.9 2-5.9-.1-4.5 1.8-7.2 6.1-8.4 3.6.4 7.3.8 10.9 1.1.6.3 1.3.6 1.9.8 1.4.3 2.7.7 4.1 1h1.9c1 .3 2 .6 3.1.9v.1c-.3.7-.4 1.9-1 2.1-4.8 2.4-4.6 6.2-3.3 10.5.5 1.5.6 3.4.1 4.9-1.1 3.4.1 5.2 3 6.8 1.3.7 2.2 2.2 3.2 3.3-7.3-1.1-14.6-2.1-21.9-3.4-3.7-.6-7.4-1.6-11.1-2.5-.4 0-.8-.3-1.2-.6z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M334 388.4c1.4-1.3 2.5-2.9 4.1-3.8 4.4-2.5 8.7-4.5 8.2-10.9-.2-2.9 1.4-6 2.3-9.5-3-.8-5.3-1.3-8.6-2.2 0 4.1-.2 7.3 0 10.6.2 3.1-1 4.7-4.1 5-.2-1.4-.5-2.9-.6-4.3-.2-4.7-.1-9.4 2.6-13.5 6.5-1.5 13.1-3 19.6-4.5 4.2-.9 7.1 1.2 7.3 5.4.1 1.3 0 2.6 0 4 .2 1.6-.8 3.7 2 4 .6 1.5 1.2 2.9 2.2 5.2-1.3 1.1-2.8 2.2-4.2 3.3-1.7 1.4-3.3 2.9-5 4.4-8.4 2.2-17.1 4.5-25.8 6.8z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M84.2 157.3c8.3-.3 14.2 3.3 18 10.7 1.5 2.9 3.8 5.3 5.7 7.9 1.5 3.6 3 7.1 4.5 10.7-2 1.6-4 3.8-5.9-.2-2 1.4-3.3 2.7-1.2 5.1 1.6 1.9.3 3.3-1.3 4.4-.4-.1-.7-.1-1.1-.2 1.5-5.4-4.1-6.7-6-10.2-.4-.7-3.5.2-5.3.4l-3.6-7.2c-1-2.3-2-4.7-3-7-.9-2.6-1.7-5.2-2.6-7.8-.8-2.6-.4-4.8 1.8-6.6z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M165.1 392.4c-1.1-1.1-1.9-2.6-3.2-3.3-2.8-1.6-4.1-3.5-3-6.8.5-1.5.3-3.4-.1-4.9-1.3-4.3-1.5-8.1 3.3-10.5.5-.3.7-1.4 1-2.1.7 0 1.3 0 2-.1 1 1.7 2.5 3.4 2.9 5.2.7 3.2.6 6.6 1.2 9.8.2 1.1 1.7 2.7 2.7 2.8 8.4.9 16.8 1.5 25.1 2.1v.1c-1.4 5.4-.9 6.2 4.1 8.1 1.2.4 2 1.8 2.9 2.8h-9c-1.3 0-2.7-.1-4-.1-2.3-.3-4.7-.6-7-.9l-5.1-.3c-1.3-.3-2.6-.5-3.9-.8-1-.1-2-.1-3-.2-1.5-1-2.9-2.9-5-.9-.6.1-1.3.1-1.9 0z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M91.1 359.6c0-1.8-.1-3.6.2-5.4.8-6.7 4.2-8.5 10.4-5.6.7.3 1.6 0 2.4 0 1 .3 2 .7 3 1 1.3 1 2.6 2 4 3.1-1.2 1.8-3.4 3.6-3.5 5.5-.2 3.5.9 7 1.5 10.4.7 2 1.2 4 2.1 5.9.8 1.8 2.1 3.4 3.2 5 .5.7 1.2 1.3 1.8 2-8.9-2.4-17.3-5.9-25-11-.1-3.6-.1-7.2-.1-10.9zM400.1 196.7c-.4 6.7-.9 13.5-1 20.2-.1 3.9.5 7.9.8 11.8.6 8.9-.3 17.7-3.8 26.1v-.2c-.6-5.2-1-10.5-1.8-15.7-1.6-10.4-3.9-20.8-.7-31.4 1.1-3.7-1.7-6.9-5.7-6.7-2.6.1-5.7.2-7.6 1.7-4 3.2-8.4 3.4-13.1 3.3-4.8-.1-9.6-.1-14.5 0-1.6 0-3.1.6-4.6 1h-5c-1.7-.3-3.3-.7-5-1 1.5-.3 3-.9 4.6-1 8.1-.8 16.3-1.4 24.4-2.1 1.5.2 3.6 1.2 4.4.6 4-3.3 9-3.8 13.6-5.5 2.5 1.9 4.8 1.2 7.2-.5 2.4-2 5.1-2.3 7.8-.6z"
        />
        <path
            style={{
                fill: "#2a2828",
            }}
            d="M133.1 203.6c-.1-1.3.2-2.8-.4-3.8-2.2-3.4-1.5-6.7-.6-10.2l3.6-15.9c2.8-4.5 5-9.5 8.5-13.4 2.8-3.1 7.3-4.8 11-7.1 1.1 0 2.1 0 3.2-.1 4.4.1 8.4 1.6 11.7 4.5.4.9.7 1.8 1.1 2.7-.5.3-1.1.9-1.6.8-4.3-.5-8.6-1.2-12.9-1.5-1.1-.1-2.4 1-4 1.8 1.1.9 1.6 1.5 1.8 1.4 3.1-1.1 5.3 0 7 2.6 1.6 2.3 3.1 4.6 4.7 7 .3.7.5 1.4.8 2.1 0 .3.1.7.2 1-2.4.1-4.5.1-5.3-3.1-.4-1.6-2.5-2.8-3.8-4.2-1.6 1.4-2.7 2.3-3.8 3.3-.2-.2-.5-.4-.7-.6.4-1.3.8-2.7 1.2-4.2-2.9.8-5.4 1.4-7.7 2.1-.6-1.1-1.1-1.9-2-3.5-.5 1.7-.7 2.4-.9 3.2-3.7 2.6-5.8 6.2-6.1 10.7-.6 4-1.2 8-1.7 12 .6 4.4 1.2 8.9 1.8 13.3-1.5-.1-3.1-.3-4.6-.4-.3-.1-.4-.3-.5-.5zm16.6-39.9c-.1-.2-.2-.6-.4-.7-.2-.1-.5.2-.8.3l.6.9c.2-.2.4-.3.6-.5z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M228.5 103.6c8.7 4.6 14.1 12.1 17.6 21.1 1.1 2.7 1.9 5.6 2.8 8.4v17.2c2.1.3 3.5.4 5 .6v.6c-2.4.1-4.9.2-7.3.2-.2-5-.4-10-.6-15.1-1.4-4.4-2.1-9.1-4.2-13.1-9.8-18.5-30.9-27.2-50.6-22.3-6.3 1.6-12.2 4.9-18.2 7.5.3-1 .7-2 1-3.1 1-.7 2-1.3 3-2 1.2-.4 2.5-.7 3.7-1.2 6.5-2.9 13.3-5.2 20.5-4.6 7 .5 13.9 1.8 20.9 2.8h-.1c1.7.7 3.4 1.4 5.1 2h-.1c.5.3 1 .6 1.5 1zM334.1 134.4c-2.6 6.5-5.1 13-11.9 16.5-.1-.2-.1-.5-.2-.7 1.2-1.5 2.7-2.9 3.6-4.6 2-3.5 3.7-7.2 5.6-10.9.3-2.7.7-5.5 1-8.2 1.1-12.1-9.3-24.6-20.8-25.9-5.1-.6-10.2-.8-15.3-.9-12.4-.1-24.9 0-37.1 0-.7 1.6-1.2 2.8-1.7 4-.4-1.7-.8-3.5-1.5-6.4 21 .5 40.8.9 60.6 1.4 13.8 4.9 20.3 20 18.4 32.4-.4 1-.5 2.2-.7 3.3z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M383 345.5c1.4-1.4 2.7-2.9 4.3-4 1.7-1.2 3.8-2 5.7-2.9 2.9.8 3 5.8 7 5-2.8 5.4-6.5 10.5-5 17.1-3.4 3-6.7 5.9-10.1 8.9v-9c.3-1.3.8-2.5.7-3.8.1-3.9 2.3-8.5-2.6-11.3z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M316.2 98.6c-19.8-.5-39.7-.9-60.6-1.4.7 2.9 1.1 4.7 1.5 6.4-.2 6.8-.6 13.6-.6 20.4 0 3.1.4 6.3.6 9.4-.2 1.6-.4 3.3-.6 4.9-.1 4.4-.2 8.8-.2 13.2-.8 0-1.5-.1-2.3-.1v-.6c0-6.5.1-12.9.1-19.4V95.9c2-.2 3.2-.4 4.5-.4 14.8 0 29.6-.2 44.3.2 4.5.1 8.9 1.9 13.3 2.9zM140.3 141.8c3.3-1.9 1.5-5 1.6-7.6.1-1.7-.8-3.5-1.4-5.6h-28.9c-.6-2.9-1.3-5.3 2.7-4.9 0 2.9 2.3 1.8 3.6 1.9 8.8.1 17.6 0 26.9 0 .1 2 .2 3.8.3 5.5.3 5.6.6 11.2.7 16.9.1 4.5-1.1 5.6-5.5 5.7-3.8.1-7.6 0-11.4-.1 2.4-.3 4.7-.9 7.1-1 4.7-.2 5.1-.4 5-5.1 0-1.6-.5-3.2-.8-4.8 0-.3 0-.6.1-.9z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M385 360.6v9c-8.3 4-16.7 8-25 12 1.7-1.5 3.3-3 5-4.4 1.4-1.2 2.9-2.3 4.2-3.3-.9-2.2-1.6-3.7-2.2-5.2 4.9-1.3 8.9-3.5 10-9 2.7.2 5.4.6 8 .9z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M80.9 318.2c.1 3.4-.1 6.8.5 10 .4 2.8 1.7 5.4 2.6 8.1-1.7.8-4 1.1-4.9 2.5-2.4 3.3-1 12.2 2 15.7.5 2.1 1.1 4.1 1.4 6.3.1.5-1 1.2-1.5 1.9-3.8-3.3-5.8-7.5-6-12.5-.1-2.5-.5-4.9-.9-7.4-1.2-6.9 1.6-13.2 2.9-19.7.4-1.8 2.6-3.3 3.9-4.9z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M420.9 96.1c-1.1.2-2.2.6-3.3.6-24 .4-48.1.7-72.1 1-5.8.1-6.1.4-6.3 6.2-.2 6.6-.6 13.2-1 19.8-.7-1-2.1-1.9-2.1-2.9-.2-8.1-.1-16.3-.1-24.7 2-.2 3.2-.5 4.5-.5h76c1.4 0 2.9.3 4.4.5zM362.1 123.6c3-.5 4.1.6 4.1 3.7-.2 13.8-.1 27.5-.1 41.3 0 1.4.2 2.9-2 1.9-1.3-2.9-2.5-5.9-3.8-8.8.5-3.5 1.4-7 1.5-10.5.3-9.1.2-18.3.3-27.6z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M116.1 381.5c-.6-.7-1.3-1.3-1.8-2-1.1-1.6-2.3-3.2-3.2-5-.9-1.9-1.4-3.9-2.1-5.9h7c1.1 1.2 2 3 3.4 3.6 3.8 1.4 7.7 2.4 11.6 3.5-1.3 2.9-2 5.8-1 9h-4c-.7-.3-1.4-.6-2.2-.9-.6 0-1.2-.1-1.8-.1-.7-.3-1.4-.6-2-.9-1-.3-2-.7-3.1-1-.2-.2-.5-.2-.8-.3z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M140.2 142.8c.3 1.6.8 3.2.8 4.8.1 4.7-.3 4.9-5 5.1-2.4.1-4.7.6-7.1 1-.2.1-.4.1-.6.1l-2.1-.3c-.4-.4-.9-.7-1.3-1.1 1.9-6.1 8-9.9 15.3-9.6z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M81.1 362.7c.5-.6 1.5-1.3 1.5-1.9-.3-2.1-.9-4.2-1.4-6.3 1.1.3 2.1.5 2.8.7 1.3-1.4 2.3-2.5 3.3-3.6.6 1.4 1.2 2.7 1.8 4.1.6 1.3 1.4 2.6 2 3.9v11c-3.4-2.7-6.7-5.3-10-7.9z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="m126.2 153.4 2.1.3c-1.4 5-2.8 9.9-4.2 14.9-1.9 0-3.3-.2-2.7-2.8.5-1.8.4-3.7.7-5.9-2.1.8-3.6 1.3-5.1 1.8-.3-1-.8-2.1-1-3.1-.9-4.2-.3-4.8 3.9-4.9 2-.1 4.1-.2 6.3-.3zM254.1 131.5c0 6.5-.1 12.9-.1 19.4-1.4-.2-2.9-.4-5-.6v-17.2c1.7-.6 3.4-1.1 5.1-1.6z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M153.1 95.7c-1.1.3-2.1.8-3.2.9-2.5.1-5 0-7.5 0-20.1.4-40.2.8-60.4 1.2 1.6-.8 3.2-2.3 4.9-2.3 22.1 0 44.1.2 66.2.2z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M116.9 161.8c1.5-.5 3-1.1 5.1-1.8-.2 2.2-.2 4.1-.7 5.9-.7 2.6.8 2.8 2.7 2.8l-3 9c-.6 1-1.3 2-1.9 3-3.3-3.3-4.5-13.5-2.2-18.9zm3.1 8.8c.1-.1.3-.1.3-.2.1 0 0-.2.1-.3-.1.2-.2.4-.4.5-.1.1-.2.1-.3.2-.1 0-.1.2-.1.3.2-.2.3-.4.4-.5z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M400.1 343.6c-4.1.8-4.1-4.2-7-5 .4-3.3.8-6.6 1.1-9.8l6.9 1.8c-.1 4.3-.1 8.7-.2 13h-.8z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M222.1 100.6c-7-1-13.9-2.3-20.9-2.8-7.2-.6-14 1.7-20.5 4.6-1.2.5-2.5.8-3.7 1.2 3.5-2 6.8-4.5 10.6-5.7 10.7-3.5 21.5-3.2 32 .7 1 .3 1.7 1.3 2.5 2zM158.3 153.2c-1.1 0-2.1 0-3.2.1-1.9-10.7-.8-21.1 3.9-30.9.7-1.4 1.8-2.7 2.7-4l.3.3c.1.7.1 1.4.2 2.1-.5 1.2-.9 2.4-1.4 3.7-.6 1.1-1.2 2.1-1.8 3.2-3.3 8.3-2.6 16.9-.7 25.5z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M400.1 196.7c-2.7-1.7-5.4-1.3-7.9.5-2.3 1.7-4.6 2.4-7.2.5 1.7-2.3 3.3-4.6 5-7 .7-.7 1.4-1.4 2.2-2.1 6 1.4 8.2 3.6 7.9 8.1z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M78 244.5c.4-2.3.8-4.6 1.1-6.9.4-1.2.8-2.4 1.3-3.6.8-2.2 2-3.8 4.7-3.4-.7 4-1.3 8.1-2.2 12-.5 2.3-1.8 4.3-4.8 3.9 0-.6-.1-1.3-.1-2z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M85.1 171.7c1 2.3 2 4.7 3 7-.3 2-.9 4-.9 6 0 4.9.1 4.9 5 4 .3 1.4.6 2.7.9 4.1-7.3-.2-7.5-.4-8.1-6.2 0-5.1 0-10 .1-14.9z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M400.1 343.6c.3 0 .6 0 .8-.1-.6 6.2-.3 12.6-5.8 17.1-1.5-6.5 2.1-11.6 5-17z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="m401.1 330.6-6.9-1.8c0-2.4-.1-4.8-.1-7.2 5.5.4 6.8 2.1 7 9zM135.7 173.7l-3.6 15.9c.3-4.3.6-8.7.9-13 .9-.9 1.8-1.9 2.7-2.9z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M209 396.6c.4-.3.8-.8 1.2-.8 5.9.5 11.9 1.1 17.8 1.7-6.3-.4-12.6-.7-19-.9z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M164.1 114.8c1.2-1.4 2.3-2.8 3.5-4.2.5-.3 1-.7 1.5-1 .7.1 1.5.2 2.2.3-2.3 2.3-4.5 4.7-6.8 7-.2-.7-.3-1.4-.4-2.1z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M195 395.6h9c.3.3.7.6 1 .9-3.3-.3-6.6-.6-10-.9z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M171.2 109.9c-.7-.1-1.5-.2-2.2-.3 1.7-1.3 3.3-2.7 5-4-.3 1-.7 2-1 3.1-.6.3-1.2.8-1.8 1.2z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M249.1 397.4c3.4-.2 6.7-.5 10.1-.7-3.4.2-6.7.5-10.1.7zM184 394.6c2.3.3 4.7.6 7 .9-2.3-.3-4.6-.6-7-.9zM262 396.7c1.3 0 2.7-.1 4-.1-1.3 0-2.6 0-4 .1zM167.1 392.5c2.1-2 3.4-.1 5 .9-1.7-.3-3.4-.6-5-.9z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M164.1 114.8c.1.7.3 1.4.4 2.1-.2.5-.3 1.1-.5 1.6-.6 0-1.3.1-1.9.1l-.3-.3c.7-1.2 1.5-2.3 2.3-3.5zM227.1 102.6c-1.7-.7-3.4-1.4-5.1-2 1.7.6 3.4 1.3 5.1 2z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M175 393.6c1.3.3 2.6.5 3.9.8-1.2-.3-2.5-.6-3.9-.8z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M177 103.6c-1 .7-2 1.3-3 2 1-.7 2-1.4 3-2z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M279 395.7c.7 0 1.4-.1 2-.1-.6 0-1.3 0-2 .1zM273.1 396.4c1-.2 2.1-.5 3.1-.7-1 .2-2.1.5-3.1.7zM296.1 394.4c1-.2 2-.5 3-.7-1 .2-2 .4-3 .7zM304.1 393.4c1-.2 2-.5 3-.7-1 .2-2 .4-3 .7z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M126.9 384.7h3.1v1c-1-.4-2-.7-3.1-1zM117 381.6c1 .3 2 .7 3.1 1-1.1-.3-2.1-.7-3.1-1zM120 382.6z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M287.1 395.3c.7-.2 1.4-.4 2.1-.7-.7.3-1.4.5-2.1.7zM311.1 392.4c.7-.2 1.4-.5 2.1-.7-.7.2-1.4.4-2.1.7z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M123.9 383.7c.7.3 1.4.6 2.2.9-.7-.3-1.5-.6-2.2-.9zM130.9 385.7l1.2.9-1.2-.9z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M169 109.6c-.5.3-1 .7-1.5 1 .5-.4 1-.7 1.5-1zM228.5 103.6c-.5-.3-1-.7-1.5-1 .5.3 1 .6 1.5 1z"
        />
        <path
            style={{
                fill: "#e8c7cf",
            }}
            d="M338.1 359.7c-2.8 4.1-2.8 8.8-2.6 13.5 0 1.5.4 2.9.6 4.3-7.4 1.1-14.9 2.1-22.3 3.3-4.1.6-4.5.4-4.7-3.7-.2-4.5-.3-9-5-11.5-1.1-.7-2.2-1.9-3.3-1.9-6.9.5-13.8 1.2-20.7 1.9-4.5.5-7.7 2.9-8 6.1-.4 4.3-.7 8.6-1 12.8-10.1.6-20.2 1.4-30.3 1.6-2.8.1-3.9-2.4-3.8-5.3 0-3.2 1.1-6.7-1.9-9.3-.3-.7-.7-1.3-1-2-1.2-.7-2.3-1.9-3.5-2-6.8-.5-13.6-.8-20.5-1.1 1.4-3 2.7-6 4.1-9 1.7.6 3.4 1.3 5 1.9.6.2 1.2.3 1.7.5.7.3 1.3.7 2 1 3.4.9 6.8 1.7 10.2 2.6 8.8 1.9 17.5 1.8 25.8-2 2.4-1.1 4.6-2.8 4.2-6 0-.6 0-1.3.1-1.9 1.8-2.1 3.9-4 5.5-6.3 4.8-7.2 4.7-15.4 4.6-23.6-.1-5.9-.3-6.2-6.2-6.1-14.6.2-29.3.5-43.9.8-1.3-.3-2.7-.6-4-.8-1.2-4-2.4-8.1-3.4-12.1-.3-1.2-.6-2.9 0-3.7 1.9-2.7.6-5.4.3-8.1-.2-1.9-.2-3.8-.1-5.7.3-4.5 1.8-9.3.8-13.5-1.6-6.4 1.1-13.2-2.4-19.3-.3-.5.2-1.6.5-2.3 2.1-4.5 1.9-4.8-2.3-7.2-1.3-.8-2.3-2.1-3.4-3.1 2.8-.2 5.6-.4 8.3-.6 7.5-.8 15-2 22.5-2.2 8.4-.2 16.8.5 25.2.9 2.6.3 5.2.8 7.9.8 6 .1 12.4 1.9 17.1-3.7 4.3-1.7 8.7-3.3 13-5h2c2.3-.3 4.6-.3 6.8-.8 8.1-1.8 16-2 23.2 2.8-2.1 3-4.3 6-6.4 9-11.4-6.9-22.9-7.5-34.6-.9-.4.2-.7.5-1.1.7-.3.1-.6.3-.8.5-.3.2-.7.5-1 .7-1.7 2-3.5 3.9-5.2 5.9h.1c-.7.1-1.3.1-2 .2-6.3 4.4-8.6 11.3-10.5 18-1.6 5.5-2.8 11.4.2 17 .1.6.2 1.3.3 1.9.1.4.2.8.4 1.1 4.3 19.5 24.9 30.3 44.4 20.6 6.1-3 10.5-7.9 14.9-12.9 2.7.3 2.3 2.4 2.3 4.1 0 6 1.1 12.3-.3 18-1.5 6.4 2.1 13.1-1.6 19.2-.3.5-.2 1.4 0 1.9 1.8 4.2 2.8 9 7.3 11.5 2.4 1.3 2.7 3.1 2.5 5.4-2.1 1-4.1 2.1-6 3.1z"
        />
        <path
            style={{
                fill: "#e3bdc7",
            }}
            d="M271 384.6c.3-4.3.6-8.6 1-12.8.3-3.3 3.5-5.7 8-6.1 6.9-.7 13.8-1.4 20.7-1.9 1-.1 2.2 1.2 3.3 1.9-7.7.7-15.4 1.1-23.1 2.3-5.8.9-7.6 4.1-7 8.4.2 1.8-.1 3.6.1 5.5.2 2.1-.9 2.9-3 2.7z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M234 369.6c.3.7.7 1.3 1 2-6.9 0-13.9-.1-20.8 0-4.1.1-8.2.6-12.3.9 1-1 2-2 3.1-3 9.7.1 19.4.1 29 .1z"
        />
        <path
            style={{
                fill: "#d4a6b1",
            }}
            d="M205 369.5c-1 1-2 2-3.1 3-.3 2.4-.7 4.8-.9 7.2-.2 2.7-.6 5-4.1 5v-.1c.3-3.1.8-6.2 1-9.3.3-5.2 1.6-6.9 7-8.7.1 1 .1 2 .1 2.9z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M107.1 349.6c-1-.3-2-.7-3-1-2.4-1.7-4.9-3.2-7-5.2-1.3-1.3-2.7-3.2-2.8-4.9-.2-3.3.6-6.7.7-10.1.1-3.8.1-7.5-.2-11.3-.4-5.2-1.5-10.3-1.6-15.4-.2-12.2-.1-24.4 0-36.6 0-.5.7-1.1 1-1.6.6.3 1.3.6 1.9.9 1.4-1.9 3.4-3.6 4.2-5.7 1.1-2.5 1.1-5.4 1.8-8.1.9-3.7 1.9-7.4 2.9-11.2 1-.4 2-.8 3.9-1.5.7 4.9 1.3 9.6 2.1 14.2.9 4.8 2 9.6 3 14.4 0 .6.1 1.3.1 1.9.3.3.6.7.9 1-1.6.6-3.2 1.2-5.3 2 .4 3.9 1.1 7.8 1.1 11.7-.1 8.2-.7 16.4-.7 24.5 0 6.9.7 13.8.9 20.8.2 6.6.7 13.3-3.8 19-.3.7-.1 1.6-.1 2.2z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M85.1 230.6c-2.7-.4-3.9 1.2-4.7 3.4-.4 1.2-.8 2.4-1.3 3.6-1.3-5.9-.8-11.7 2.5-16.8 2.1-3.3 3.6-6.3 2.6-10.5-1.2-4.9 1.3-8.6 5.7-11.5 5.7 4.2 12.4 6 19.6 5.8 1.1 0 2.7 0 3.3-.6 3.1-3.7 6.8-2.3 10.5-1.8 3.3.5 6.5.9 9.8 1.4.2.2.2.4.3.6-1.1 1.1-2.2 2.2-3.5 3.6 2 .6 4.1 1.2 6.2 1.7-.3.4-.7.7-1 1.1-3.4-.5-6.8-.9-10.2-1.7-6.4-1.5-12.7-3.4-19.4-1.5-2.6.7-4.1 1.8-4.5 4.4-.5 3.7-1 7.5-1.7 11.2-2.5 13.1-6.8 25.9-5.3 39.5-1-3.2-2.2-6.4-2.9-9.7-1.3-6.3-2.2-12.6-3.5-18.9-.2-1.1-1.7-2.2-2.5-3.3z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M94 262.6c-1.5-13.6 2.8-26.4 5.3-39.5.7-3.7 1.2-7.4 1.7-11.2.4-2.7 1.9-3.7 4.5-4.4 6.8-1.9 13 0 19.4 1.5 3.3.8 6.8 1.1 10.2 1.7-.9.7-1.8 1.8-2.8 1.9-4.6.6-8.8-.7-12.9-2.7-5.4-2.7-11.6-1.3-13 2.8-.7 2-.9 4.2-1.2 6.3-1.1 6.1 3 10.5 5.9 14.9 6.9 10.6 5.1 21.7 3.1 32.9-1-4.8-2.1-9.5-3-14.4-.8-4.6-1.4-9.3-2.1-14.2-1.9.7-2.9 1.1-3.9 1.5l-1.8-3.9c-1 .9-2.1 1.7-3 2.7-.5.6-.8 1.5-1 2.2-1.6 7.9-3.9 15.7-3.1 23.9-.6-.3-1.3-.6-1.9-.9-.3-.4-.4-.8-.4-1.1z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M87.2 351.6c-1 1.1-2 2.2-3.3 3.6-.6-.2-1.7-.4-2.8-.7-3-3.5-4.4-12.4-2-15.7 1-1.3 3.2-1.7 4.9-2.5 2.3 1.5 3.3 3.5 3.2 6.3-.2 3 0 6 0 9z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M367 202.5c-8.1.7-16.3 1.3-24.4 2.1-1.5.1-3.1.7-4.6 1h-3c-2.7.3-5.4.6-8 1h-3c-.7 0-1.4-.1-2.1-.1.2-1.1.5-2.2.8-3.6-2.5-.5-4.9-.9-7.2-1.5-2.2-.5-3.5-2.3-1.8-3.8 3.2-2.6 1.3-5.3 1.2-8 2.4.2 5 .1 7.3.7 4.1 1 4.3 2 2.7 5.9-.6 1.5-.6 3.2-1 5.3h16c2.5 0 4.9.1 7.4.1h1.8l6.9-1.2c1 .1 2 .2 3 .2 3.3-.6 6.7-1.3 10-1.9-.6 1.3-1.3 2.6-2 3.8z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M369.1 198.7c-3.3.6-6.7 1.3-10 1.9-1.1-7.7-2-15.3-3.3-23-.2-1.4-1.9-2.5-3-4-.6 1-.9 1.5-1.2 2.1-.2-.2-.5-.3-.6-.5-1.5-2.3-1.5-5.8-5.3-6.4-.5-.1-1.2-3.4-.5-4.6 1.5-2.6 3.7-4.8 6.1-7.7 1.8 2.1 3.5 3.9 5.1 5.7-3.3.5-6.6-.5-7.9 3.1-.3.8.3 2.3 1 2.8 1 .7 2.6 1.3 3.8 1.2 4.2-.6 5.8 1.9 6.9 5.2.6 5.1 1.4 10.1 3.7 15.2 1.1-1.4 1.8-2.2 2.4-2.9.1 3.9-3.1 8.7 3.9 9.7-.5.8-.8 1.5-1.1 2.2z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="m356.1 200.4-6.9 1.2c.1-5.8-.9-11.1-6.7-15.7-.7 6.8 2.3 11.2 4.9 15.7-2.5 0-4.9-.1-7.4-.1l-3-6.9c-.4-1.6-.7-3.3-1.1-4.9.1-.7.2-1.4.2-2.1 5.5-1.9 6.4-3.8 4.2-8.9-.1-.2.4-.7.6-1.1l2.1 1.8c.6.7 1.2 1.5 1.9 2.1 1.3.9 2.7 1.6 4.2 2.5v-5.3c.2-.1.3-.1.5-.2 1.5 3.4 3 6.9 4.5 10.3.2.5.6 1.2.5 1.4-3 3.9.7 6.8 1.5 10.2z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M336.2 187.6c-.1.7-.2 1.4-.2 2.1-2.5-.1-5-.3-8.4-.5 5.4-6.4 3.9-11.7-.5-16.7-1.7-3-3.4-6-5.2-9-1.6-3.5-3.1-7-4.8-10.7 1.8-1 3.3-1.9 4.9-2.7 0 .2.1.5.2.7 0 1.8-.5 4 .2 5.5 3.7 7.3 7.8 14.5 11.6 21.8.7 1.2.7 2.8 1.1 4.2.3 1.8.7 3.5 1.1 5.3z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M364.1 173.5c.9 1.7 1.8 3.4 2.6 5.1.1.7.2 1.3.2 2-.7 1.2-1.3 2.4-1.7 3.2-1.8-3.1-3.5-6.2-5.2-9.2v-5.9c1.7 2 2.9 3.4 4.1 4.8z"
        />
        <path
            style={{
                fill: "#2a2828",
            }}
            d="M338.5 158c-5.2-2.2-2.9-5.9-2.7-8.9 3 1.9 3.7 4.6 2.7 8.9z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M336.1 163.6c.4 1.2.9 2.4 1.4 3.8-.7.7-1.5 1.4-2.1 2-.2 2.1 3.3 4.2-.8 6.1-.8-4.4-1.1-8.3 1.5-11.9zM340.7 175.7c-.4-.2-.8-.4-1.7-.8 1.7-1.2 3.1-2.2 4.4-3.2.8 1.2 1.7 2.5 2.8 4-.4.2-1 .6-1.7 1-1.3-.3-2.5-.6-3.8-1z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M340.7 175.7c1.3.4 2.5.7 3.8 1.1.1 1.1 1.2 2.8-1.4 2.6l-2.1-1.8c-.1-.7-.2-1.3-.3-1.9z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M336 163.6c0-1 .1-1.9.2-3.4 1.1.5 1.8.9 2.6 1.3-1 .6-1.8 1.4-2.8 2.1z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M116.9 161.8c-2.3 5.4-1.1 15.6 2.2 18.8 2.1 2.2 4.2 4.4 6.3 6.7-.2.5-.4 1-.7 1.6-2 .2-4 .3-5.9.8-1.3.3-2.6 1.2-3.8 1.9-.4-.5-.9-.8-1.2-1.4-.6-1.2-1-2.4-1.5-3.6-1.5-3.6-3-7.1-4.5-10.7 4-2.9 2.1-6.7 1.4-10.3v-13.3h15.6c.4.4.9.7 1.3 1.1-2.1.1-4.2.3-6.4.3-4.2.2-4.7.8-3.9 4.9.3 1.1.8 2.2 1.1 3.2z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M112.4 186.6c.5 1.2.9 2.4 1.5 3.6.3.5.8.9 1.2 1.4.3 1.4.6 2.8.9 4.3-4.2 1.6-8.1 2.5-12 0 1.6-1.1 2.9-2.5 1.3-4.4-2.1-2.4-.7-3.7 1.2-5.1 1.9 4 3.9 1.7 5.9.2zM93 192.7c-.3-1.4-.6-2.7-.9-4.1-.2-.9-.3-1.8-.5-2.7 1.9-.2 5-1 5.3-.4 1.9 3.4 7.5 4.8 6 10.2l-9.9-3z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M109.2 202.1c.3-1 .5-2 .8-3 .3.1.6.1.9.2-.1 1-.2 2-.3 2.9-.5 0-.9-.1-1.4-.1z"
        />
        <path
            style={{
                fill: "#b0e7df",
            }}
            d="M368 178.6v-56.8h-27.2c.5-7.3.9-13.9 1.4-20.7 26.4-2.3 52.4-1.2 78.3-1.4v21.8h-27.6c-.3 1.3-.6 2-.7 2.8-.4 13-.7 25.9-1.2 38.9-.3 7.8-.7 15.6-1.1 23.4-1-.1-2-.1-2.9-.5-6.3-2.5-12.7-5-19-7.5z"
        />
        <path
            style={{
                fill: "#95d9cd",
            }}
            d="m369.1 198.7.9-2.1-.3-6h20.4c-1.7 2.3-3.3 4.6-5 7-4.6 1.7-9.6 2.2-13.6 5.5-.8.7-2.9-.3-4.4-.6.6-1.2 1.3-2.5 2-3.8zM368 178.6c6.4 2.5 12.7 5 19.1 7.5.9.3 1.9.3 2.9.5-.1 2.6-2 2.1-3.6 2.1-5.8 0-11.5 0-17.3-.1-.7-2.7-1.4-5.3-2.2-8-.1-.7-.2-1.3-.2-2h1.3z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M367 180.6c.7 2.7 1.4 5.3 2.2 8 .2.7.3 1.3.5 2l.3 6c-7-1-3.8-5.8-3.9-9.7-.6.8-1.3 1.5-2.4 2.9-2.2-5.1-3.1-10.1-3.7-15.2 1.7 3 3.5 6.1 5.2 9.2.5-.7 1.1-2 1.8-3.2z"
        />
        <path
            style={{
                fill: "#8bd3c7",
            }}
            d="M124.9 152.3h-15.6v13.3c-1.7-1.2-3.6-2.2-5.2-3.5-5.6-4.5-11.2-9.2-18.4-15-.6-14.1-1.2-30.3-1.9-47.3H102c-.1 2.2.3 4.5-.5 6.4-2.7 6.4-1.6 13.3-2.5 19.9-.4 2.7-.6 5.4-.9 8.6 2.7 0 5.1.6 7-.1 3-1.2 7.4.6 8.9-3.8 6.9 0 13.9.1 20.8-.1 3.3-.1 4.9.9 4.9 4.4 0 2.3.3 4.6.5 6.9 0 .3-.1.6-.1 1-7.2-.5-13.3 3.3-15.2 9.3z"
        />
        <path
            style={{
                fill: "#95d9cd",
            }}
            d="M114 130.6c-1.5 4.5-5.9 2.7-8.9 3.8-1.8.7-4.2.1-7 .1.3-3.2.5-5.9.9-8.6.9-6.6-.2-13.4 2.5-19.9.8-1.9.3-4.3.5-6.4 10.5-.1 21 0 31.5-.2 3.6-.1 7.3-.6 10.9-.8 2.8-.1 4.7.8 4.7 4.3 0 4 .8 7.9.8 11.9-.1 5.4-1.6 6.7-7 6.7h-34.4v8.7c1.7.2 3.6.3 5.5.4z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M338.1 359.7c2-1 4-2.1 6-3.1 3.8-1.1 7.7-2 11.4-3.3 2.8-.9 5-2.6 4.5-6.2-1.5-11.5-2-23.1-.1-34.6.1-.5-.1-1-.1-1.5-.2-7.5-1.3-15.2-.2-22.6.9-6.4-.1-13.6 4.5-19.2.1-.1 0-.3.1-.5 1 0 2 0 3-.1 1.4-1.4 3.4-2.6 4.1-4.3 3.8-10.2 7.3-20.5 10.9-30.7 1.1-.7 2.1-1.3 3.8-2.4 2.2 4.7 4.1 9 6.1 13.4-3.3-.2-3.8 2-4 4.5-.4 8.1-.7 16.2-1 24.3-.4 11.8-.5 23.5-1.1 35.3-.4 8.5-1 17 1.4 25.3.7 2.4-.5 3.5-1.9 5-1.9 2.1-5.6 2.8-5.4 6.5l-3 3c-3.6 2.1-4 6.1-4.8 9.5-1.3 5.1-1.5 5.6-7.2 6.5v-4c-.2-4.2-3.1-6.3-7.3-5.4-6.6 1.6-13.2 3.1-19.7 4.6z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M392 244.6c-2-4.3-4-8.7-6.1-13.4-1.7 1-2.8 1.7-3.8 2.4 1.8-6.1 3.7-12.2 5.6-18.3.6-2.1 1.8-4 2-6.1.1-1.6-.6-3.6-1.7-4.8-.5-.6-2.7-.2-4 .3-2.9 1.1-5.6 3.5-8.5 3.6-7.6.3-15.2-.4-22.8-.8-1.6-.1-3.1-.6-4.7-.9 1.5-.3 3.1-.9 4.6-1 4.8-.1 9.6-.2 14.5 0 4.7.1 9-.1 13.1-3.3 1.8-1.5 5-1.6 7.6-1.7 4-.1 6.8 3 5.7 6.7-3.2 10.6-.8 21 .7 31.4.8 5.2 1.2 10.5 1.8 15.7-.9-.9-2.4-1.7-2.7-2.8-.7-2.2-.9-4.6-1.3-7z"
        />
        <path
            style={{
                fill: "#e4e4e4",
            }}
            d="m377 348.6 3-3c1 0 1.9 0 2.9-.1 5 2.8 2.7 7.4 2.8 11.3 0 1.2-.5 2.5-.7 3.8-2.7-.3-5.3-.7-8-1v-11z"
        />
        <path
            style={{
                fill: "#d4a6b1",
            }}
            d="M107.1 349.6c0-.7-.2-1.5.1-1.9 4.5-5.7 4-12.4 3.8-19-.2-6.9-.9-13.8-.9-20.8 0-8.2.7-16.4.7-24.5 0-3.9-.7-7.8-1.1-11.7 2.1-.8 3.7-1.4 5.3-2 .7.3 1.3.7 2 1 6.5-.1 6.7-.1 8.7-5.5 1.5-3.9 2.5-7.9 4-11.8 1.6-4.3 3.5-8.5 5.3-12.7 2.1-1.3 4.3-2.6 7.1-4.2.4 2 1 3.5.8 5-.4 3.9 1.2 6.1 4.9 6.9-1.1 1.1-2.1 2.2-3.2 3.3-.2.2-.4.5-.7.8-2.9 5.8-5.6 11.6-6 18.3-.7 13.3 4.3 24.3 12.9 34-4.1 2.3-4.7 4-3.8 9.3.5 2.7.4 5.5.5 8.3.1 3.7-.2 7.5.3 11.2.4 3.1.6 5.9-.2 9.1-1.3 5.3-1.8 10.9-2.6 16.3 2.5 1.1 4.7 2 6.9 3-3.6-.4-7.3-.8-10.9-1.1-6.1-2.6-9-.8-9.2 5.4-.1 1.8-.7 3.7-1 5.5l-9.9-3c.1-5.6-.9-10.7-6.1-14-1.3-.7-2.6-1.3-3.9-2-1.2-1.1-2.5-2.1-3.8-3.2z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M152.1 361.8c-2.2-1-4.4-1.9-6.9-3 .8-5.4 1.2-11 2.6-16.3.8-3.2.6-6 .2-9.1-.5-3.7-.1-7.5-.3-11.2-.1-2.8 0-5.6-.5-8.3-.9-5.2-.3-7 3.8-9.3 6.3 5.7 13.6 9.3 22 10.7-2.7 6.8-1.3 13.9-.8 20.8.7 9.4.9 18.4-8.1 24.4.4 1.5.7 2.8 1 4.1-.7 0-1.3 0-2 .1v-.1c-.5-2.2-1.5-2.6-3.1-.9h-1.9c-.7-3-2.3-2.4-4.1-1-.7-.3-1.3-.6-1.9-.9z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M131 371.6c.4-1.8 1-3.7 1-5.5.2-6.3 3.1-8.1 9.2-5.4-4.3 1.1-6.2 3.8-6.1 8.4.1 2 1.3 4.9-2 5.9-.7-1.2-1.4-2.3-2.1-3.4z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="m131 371.6 2.1 3.3c-.7.2-1.3.5-2 .7-3.9-1.1-7.8-2-11.6-3.5-1.4-.5-2.3-2.4-3.4-3.6h5c3.2 1.1 6.6 2.1 9.9 3.1z"
        />
        <path
            style={{
                fill: "#d4a6b1",
            }}
            d="M160 363.7c1.6-1.7 2.6-1.3 3.1.9-1.1-.3-2.1-.6-3.1-.9zM154 362.6c1.8-1.4 3.4-2 4.1 1-1.4-.3-2.8-.6-4.1-1z"
        />
        <path
            style={{
                fill: "#dec3c9",
            }}
            d="M377 348.6v11c-1.1 5.5-5.2 7.7-10 9-2.7-.3-1.8-2.5-2-4 5.7-.9 5.9-1.4 7.2-6.5.9-3.4 1.3-7.4 4.8-9.5z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M91.6 185.9c.2.9.3 1.8.5 2.7-4.9 1-5 .9-5-4 0-2 .6-4 .9-6 1.2 2.5 2.4 4.9 3.6 7.3z"
        />
        <path
            style={{
                fill: "#e3bdc7",
            }}
            d="M165.1 364.6c-.3-1.3-.6-2.5-1-4.1 9-5.9 8.8-15 8.1-24.4-.5-6.9-1.9-14 .8-20.8 1.3-.1 2.6-.2 4-.2 12.9-.5 23.8-9.9 26.9-22.6.3-4 1-8 .8-12-.2-4.1-1.4-8.1-2.1-12.2-1.1-6.8-4.3-12.3-9.5-16.7l-4.1-4.1-.9-.9-.1-.1c-.6-.3-1.3-.6-1.9-.9l-.1-.1c-.3-.2-.6-.5-.9-.7-2.7-1.3-5.3-2.5-8-3.8-.7-1.2-1.3-2.3-2-3.5h4c10 1.7 20 3.3 30 5 1.1 1.1 2.1 2.4 3.4 3.1 4.2 2.4 4.3 2.7 2.3 7.2-.3.7-.8 1.8-.5 2.3 3.5 6.1.8 12.9 2.4 19.3 1 4.2-.5 9-.8 13.5-.1 1.9-.1 3.8.1 5.7.3 2.7 1.6 5.3-.3 8.1-.6.8-.3 2.5 0 3.7 1 4.1 2.3 8.1 3.4 12.1h-12.5c-5.2 0-5.4.3-5.5 5.3v7.5c0 11.1 4.5 20.1 13 27.2-1.4 3-2.7 6-4.1 9h-5c-5.4 1.8-6.7 3.5-7 8.7-.2 3.1-.6 6.2-1 9.3-8.4-.7-16.8-1.3-25.1-2.1-1-.1-2.5-1.7-2.7-2.8-.7-3.2-.5-6.6-1.2-9.8-.5-1.8-1.9-3.4-2.9-5.2z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M111 352.7c1.3.7 2.6 1.3 3.9 2 .4 4.7.7 9.3 1.1 14h-7c-.6-3.5-1.6-7-1.5-10.4.1-2 2.3-3.8 3.5-5.6z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M348.1 206.6c1.6.3 3.1.9 4.7.9 7.6.4 15.2 1 22.8.8 2.9-.1 5.6-2.5 8.5-3.6 1.2-.5 3.4-.9 4-.3 1.1 1.2 1.8 3.2 1.7 4.8-.2 2.1-1.3 4.1-2 6.1-1.9 6.1-3.7 12.2-5.6 18.3-3.6 10.3-7.1 20.6-10.9 30.7-.6 1.7-2.7 2.9-4.1 4.3-.3-4.1-.5-8.2-1-12.3-1.1-8.3-2.4-16.6-3.5-24.9-.5-4.2-1.4-8.5-.8-12.6.5-3.7-.6-5.6-3.6-6.5-5.7-1.9-11.5-2.4-17.1.8-9.7 5.6-19.7 5.7-30 1.6.8-.7 1.7-2 2.3-1.9 4.5 1.2 8.2-1.3 12.2-2.3 1.2-.3 2.6.2 3.7-.3s1.9-1.6 2.8-2.5h2c2 2 6.8 1.5 9-1 1.5-.1 3.2-.1 4.9-.1z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M343.1 206.6c-2.2 2.4-7 3-9 1 .3-.6.7-1.3 1-1.9h3c1.6.3 3.3.6 5 .9z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M137.9 179.3c.4-4.5 2.4-8.1 6.1-10.7.3.4.5.7.8 1.1-.3-.4-.5-.7-.8-1.1.2-.8.4-1.5.9-3.2.9 1.5 1.4 2.4 2 3.5 2.3-.6 4.8-1.3 7.7-2.1-.4 1.5-.8 2.9-1.2 4.2.2.2.5.4.7.6 1.1-1 2.3-2 3.8-3.3 1.3 1.4 3.4 2.6 3.8 4.2.9 3.2 2.9 3.2 5.3 3.1.6.4 1.4.7 1.6 1.3.6 1.3 1 2.6 1.5 4 0 .3-.1.5-.3.7.1.7.1 1.4.2 2 .2.6.5 1.2.8 1.8-.3 0-.6.1-.9.2v.1c-.1-.2-.2-.3-.4-.4-.9 1.5-1.7 2.9-2.6 4.4-.7 2.7-1.4 5.3-2 8-.6.2-1.5.1-1.8.5-3.2 4.4-7.1 4.6-12 2.8-1.7-.6-4 .5-6.6.9 1.3 2 1.9 2.9 2.5 3.9-3.1-.4-6.2-.7-9.2-1.1-.6-4.4-1.2-8.9-1.8-13.3 5.7-.1 5.7-.1 6-6.7 2.3-3.9-1.3-3.9-3.2-5-.2-.2-.5-.3-.9-.4z"
        />
        <path
            style={{
                fill: "#8bd3c7",
            }}
            d="M158.3 153.2c-1.9-8.6-2.5-17.2.7-25.6h1c-.3 1.7-.6 3.5-.7 4.5 2.9 3.3 5.4 6.2 7.8 9 2.4 2.9 4.5 6.2 7.2 8.7 2.9 2.7 3.6 7.9 8.8 7.7 4.8 7 11.2 11.1 20 10.9-.4 1.2-.4 2.9-1.2 3.6-5.4 4.6-10.7 9.8-18.9 6.5-1.6-4.9-2.8-9.9-5-14.5-1.5-3.1-3.4-6.7-8-6.4-3.4-2.8-7.3-4.3-11.7-4.4z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M166.1 172.5c-1.6-2.3-3.1-4.6-4.7-7-1.7-2.5-3.9-3.6-7-2.6-.2.1-.7-.5-1.8-1.4 1.6-.8 2.9-1.9 4-1.8 4.3.3 8.6 1 12.9 1.5.5.1 1-.5 1.6-.8 2.8.6 4.1 2.3 4.1 5.1-.2 1.2-.4 2.5-.4 2.7-3.4 1.7-6 3-8.7 4.3z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="m166.1 172.5 8.6-4.3c0-.2.2-1.4.4-2.7 3.6 4.1 4.2 9 4.5 14.1l-1.8 2.1c-.7.4-1.3.8-2 1.2-.7-.4-1.4-.9-2.2-1.3h-.3c-1.1-.2-2.2-.5-3.3-.7-.5-1.3-.8-2.7-1.5-4-.3-.6-1.1-.9-1.6-1.3-.1-.3-.2-.6-.2-1 2-1.5.9-1.8-.6-2.1z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M179.7 179.6c-.3-5.1-1-10.1-4.5-14.1-.1-2.7-1.4-4.5-4.1-5.1-.4-.9-.7-1.8-1.1-2.7 4.6-.3 6.5 3.4 8 6.4 2.2 4.6 3.4 9.6 5 14.5 0 2.1.1 4.2 0 6.3-.1 2.3 1.3 2.7 3.1 2.7-1.4 1.8-2.8 3.5-4 5.3-.8 1.2-1.2 2.6-1.8 3.9-.5-.5-1-.9-1.5-1.4.3-5.2.6-10.5.9-15.8z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M138 204.6c3.1.4 6.2.7 9.2 1.1l.3.1h.3c.5.2 1 .4 1.5.7 1.6.1 3.1.2 4.7.4 2.1.5 4.2.9 6.2 1.4-1.3.8-2.7 1.6-4 2.4-1.7.1-3.4.1-5 .2-1.2-.3-2.4-.9-3.6-1-3.8-.2-7.6-.1-11.4-.2-2.1-.6-4.2-1.2-6.2-1.7 1.3-1.3 2.4-2.5 3.5-3.6 1.4 0 2.9.1 4.5.2z"
        />
        <path
            style={{
                fill: "#a16d78",
            }}
            d="M142.2 184.6c-.3 6.6-.3 6.6-6 6.7.6-4 1.2-8 1.7-12 .4.1.7.2 1.1.3 1.1 1.7 2.1 3.4 3.2 5z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M166.1 172.5c1.4.2 2.6.6.8 2.1-.3-.7-.5-1.4-.8-2.1zM149.7 163.7c-.2.2-.4.4-.6.5l-.6-.9c.3-.1.6-.3.8-.3.2 0 .3.4.4.7z"
        />
        <path
            style={{
                fill: "#95d9cd",
            }}
            d="M183.2 157.6c-5.3.1-6-5.1-8.8-7.7-2.7-2.5-4.8-5.8-7.2-8.7-2.4-2.9-4.9-5.7-7.8-9 .2-1 .4-2.7.7-4.5.3-1.1.5-2.1.8-3.2.5-1.2.9-2.4 1.4-3.7.6-.8 1.2-1.5 1.7-2.3.2-.5.3-1.1.5-1.6 2.3-2.3 4.5-4.7 6.8-7 .6-.4 1.2-.8 1.8-1.3 6.1-2.5 11.9-5.9 18.2-7.5 19.6-4.9 40.8 3.8 50.6 22.3 2.1 4 2.8 8.7 4.2 13.1-1.1-.1-2.5.2-3.4-.3-6.6-4-13.2-2.7-19.9-.5-1.5-2.1-3-4.2-4.6-6.2-.7-1.1-1.1-2.4-2.1-3.2-7.1-6.3-18.9-5.9-23.9-3-8.2 4.7-13.1 11.5-11.9 21.5v1.6c.3 2.2-2 5 1.6 6.3.4 1.7.8 3.3 1.3 4.9z"
        />
        <path
            style={{
                fill: "#8bd3c7",
            }}
            d="M222.7 135.7c6.7-2.2 13.3-3.5 19.9.5.9.5 2.2.2 3.4.3.2 5 .4 10 .6 15.1h-.1c-1.7-1-3.5-1.8-4.9-3.1-3.1-2.6-6.3-3.7-10.4-2.2-2.3.8-4.8.9-7.2 1.3-.4-3.9-.9-7.9-1.3-11.9z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M246.5 151.6s.1.1 0 0c2.5 0 5-.1 7.4-.2.8 0 1.5.1 2.3.1.6 0 1.2 0 1.8.1 2.3 1.3 4.7 2.6 7 3.9-2.6-.4-5.2-.6-7.7-1.2-4.2-1-8.3-1-12 1.5-1.8 1-3.6 2-5.5 2.9-.3-.7-.6-1.4-.9-2l.1-.1c.4-.5.8-1 1.2-1.6 2.1-1.1 4.2-2.3 6.3-3.4z"
        />
        <path
            style={{
                fill: "#b0e7df",
            }}
            d="M257.1 133.4c-.2-3.1-.6-6.3-.6-9.4.1-6.8.4-13.6.6-20.4.5-1.2 1-2.4 1.7-4 12.3 0 24.7-.1 37.1 0 5.1 0 10.3.3 15.3.9 11.5 1.3 21.9 13.8 20.8 25.9-.2 2.8-.6 5.5-1 8.2-8.7.7-13.9 6.4-17.7 13.3-3.8 6.9-8.9 12.5-15.5 16.8-1-1.6-1.8-3.4-3-4.9-1.3-1.5-2.7-3.7-4.4-4-9.4-1.7-10.5-.7-10.6 8.8v1c-1.4.1-2.9.1-4.3.2-.3 0-.5 0-.7-.2-.3-.3-.5-.6-.8-.9-.7-1.4-1.4-2.7-2.2-4.1-.4-1.7-.4-3.6-1.2-5.2-2.5-5.4-5-10.8-8.1-15.9-1.1-2.3-1.4-6-5.4-6.1zm24.3-11.8v12.9c7.8 0 15.2.2 22.6-.1 3.8-.1 6.1-3.1 6-6.6-.1-3.3-2.4-6.1-5.9-6.2-7.4-.1-14.9 0-22.7 0z"
        />
        <path
            style={{
                fill: "#95d9cd",
            }}
            d="M298 164.8c6.6-4.3 11.6-9.9 15.5-16.8 3.8-6.9 9-12.6 17.7-13.3-1.8 3.6-3.6 7.3-5.6 10.9-.9 1.7-2.4 3.1-3.6 4.6-1.5.9-3.1 1.7-4.9 2.7 1.7 3.7 3.2 7.2 4.8 10.7-1.3 1.1-3.2 1.9-3.9 3.3-2.6 5.6-6.4 10.1-11.1 14-2.4-3.7-4.7-7.4-7.1-11.1-.6-1.7-1.2-3.4-1.8-5zM257.1 133.4c4 0 4.3 3.8 5.7 6.1 3.1 5.1 5.6 10.5 8.1 15.9.7 1.6.8 3.5 1.2 5.2-1.9-1.3-3.9-2.7-5.8-4-.4-.4-.8-.7-1.1-1.1-2.3-1.3-4.7-2.6-7-3.9 0-3.1.1-6.3-.1-9.4-.1-1.3-1-2.5-1.5-3.8.1-1.7.3-3.3.5-5z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M256.5 138.4c.5 1.3 1.4 2.5 1.5 3.8.2 3.1.1 6.3.1 9.4-.6 0-1.2 0-1.8-.1 0-4.4.1-8.7.2-13.1z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M120.1 170.6c.1-.2.3-.3.4-.5 0 .1 0 .2-.1.3-.1.1-.2.1-.3.2zM120 170.6l-.4.4c0-.1 0-.2.1-.3.1 0 .2 0 .3-.1z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M160.9 124.4c-.3 1.1-.5 2.1-.8 3.2h-1c.5-1.1 1.1-2.1 1.8-3.2zM164 118.5c-.6.8-1.2 1.5-1.7 2.3-.1-.7-.1-1.4-.2-2.1.6-.1 1.2-.2 1.9-.2z"
        />
        <path
            style={{
                fill: "#333233",
            }}
            d="M285.9 250.5c1.7-2 3.5-3.9 5.2-5.9.3-.2.7-.5 1-.7.3-.2.6-.3.8-.5.4-.2.7-.5 1.1-.7 11.7-6.6 23.2-6 34.6.9 8.8 8.1 13.1 18 12.6 30-.3 8.4-3.4 15.8-7.5 22.9-4.4 5-8.8 9.9-14.9 12.9-19.5 9.7-40.1-1.2-44.4-20.6l.6-1.2-.1.1c2.1.2 2.8-.6 3.1-2.8 1.3-9.9 3.9-19.4 8.1-28.4.6-1.3 1.2-2.8 1.4-4.2.2-.5-1-1.2-1.6-1.8zm34.2 26.5c.1-13.7-10.8-21.1-21.4-15.7-7 3.6-12.3 12.1-10.6 21 2 10.4 12.5 15.7 21.7 10.6 6.8-3.8 9.9-9.8 10.3-15.9z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M311 214.6c10.2 4.1 20.3 4 30-1.6 5.6-3.2 11.4-2.6 17.1-.8 3 1 4.1 2.9 3.6 6.5-.6 4.1.3 8.4.8 12.6 1.1 8.3 2.4 16.6 3.5 24.9.5 4.1.7 8.2 1 12.3-1 0-2 0-3 .1-4.3-4.5-5.4-10.5-7-16.2-1.6-5.7-2.7-11.5-4.3-17.3-.8-2.9-2.8-5.1-5.8-3-3.8 2.7-7.8 2.2-11.9 2.3-7.3-4.8-15.1-4.7-23.2-2.8-2.2.5-4.5.6-6.8.8 1.3-3.5 4.8-1.9 7.2-2.9 3.4-1.4 7.3-1.7 9.9-5.3-.9-.7-1.4-1.4-2.1-1.6-4.7-1.3-9.5-2.7-14.3-3.7-3.7-.8-7.5-1.2-11.3-1.3-3.1-.1-6.2.5-9.3.8-9.3 1-18.7 2.1-28 3.1-1.6.2-3.2.3-4.7.1-8.9-.9-17.6-.3-25.3 4.9-1.1.8-2.7 2.4-2.5 3.2.4 1.3 1.8 3 3.1 3.3 4.3 1.1 8.7 1.8 13.1 2.4 6.7.8 13.5 1.3 20.1 2.3 1.5.2 2.8 1.8 4.1 2.8-8.4-.3-16.8-1.1-25.2-.9-7.5.2-15 1.5-22.5 2.2-2.8.3-5.6.4-8.3.6-10-1.7-20-3.3-30-5-.5-3.7-2.8-.3-4-1 0-1.5.1-3.1.1-4.6 0-1.9.5-4-.2-5.5-2.3-5-2.5-5.7 3-6.1 12.7-1 25.5-1.2 38.2-1.5 2.8-.1 5.6.7 8.4.7 9.1 0 18.2.2 27.2-.3 2.5-.1 6.7 1.5 7.5-3.3.7-.1 1.3-.1 2-.2.7.6 1.3 1.3 2 1.9 1.6 0 3.4.3 4.9-.1.9-.2 1.5-1.7 2.2-2.5 1-.1 2-.2 2.9-.3.7.3 1.3.6 2 .9.7-.3 1.4-.6 2-.9 1.3 0 2.7 0 4-.1 2.2 1 4.5 2.4 6-.9 1.7 0 3.3-.1 5-.1h2c5 .3 10 .6 14.9.9.6.1 1.2.2 1.9.2z"
        />
        <path
            style={{
                fill: "#e3bdc7",
            }}
            d="M335 234.6c4.1-.1 8.1.4 11.9-2.3 3-2.2 5.1.1 5.8 3 1.6 5.7 2.7 11.5 4.3 17.3 1.6 5.7 2.6 11.6 7 16.2 0 .2 0 .4-.1.5-4.6 5.7-3.5 12.8-4.5 19.2-1.1 7.4 0 15 .2 22.6 0 .5.2 1 .1 1.5-1.9 11.6-1.4 23.1.1 34.6.5 3.6-1.7 5.2-4.5 6.2-3.8 1.3-7.6 2.2-11.4 3.3.2-2.3-.1-4.1-2.5-5.4-4.5-2.4-5.4-7.3-7.3-11.5-.2-.6-.3-1.5 0-1.9 3.6-6.1.1-12.7 1.6-19.2 1.3-5.7.3-12 .3-18 0-1.8.5-3.8-2.3-4.1 4.1-7.1 7.2-14.5 7.5-22.9.5-12.1-3.8-22-12.6-30 2.2-3.1 4.3-6.1 6.4-9.1z"
        />
        <path
            style={{
                fill: "#bc4e70",
            }}
            d="M220.9 360c-.6-.2-1.2-.3-1.7-.5-3.1-9.1-6.3-18.2-9.4-27.4-1.6-4.8.2-8.1 5.1-8.3 4.1-.2 6.3-2.3 8.2-5.3 14.6-.3 29.3-.5 43.9-.8 5.9-.1 6.1.2 6.2 6.1.1 8.2.2 16.4-4.6 23.6-1.5 2.3-3.6 4.2-5.5 6.3-3.7 0-7.4 0-11-.1-.7 0-1.3 0-2 .1-.7.3-1.3.6-2 .9-2.3.3-4.7.7-7 1-4.7-.2-9.4-.7-14-.3-1.5.1-2.7 2.8-4 4.3-.8.1-1.5.2-2.2.4z"
        />
        <path
            style={{
                fill: "#e4e4e4",
            }}
            d="M265 240.6c-1.4-1-2.6-2.6-4.1-2.8-6.7-1-13.4-1.4-20.1-2.3-4.4-.6-8.8-1.3-13.1-2.4-1.3-.3-2.8-2-3.1-3.3-.2-.8 1.4-2.4 2.5-3.2 7.7-5.2 16.4-5.8 25.3-4.9 1.6.2 3.2.1 4.7-.1 9.3-1 18.7-2.1 28-3.1 3.1-.3 6.2-.9 9.3-.8 3.8.1 7.6.5 11.3 1.3 4.8 1 9.6 2.4 14.3 3.7.7.2 1.3.9 2.1 1.6-2.6 3.6-6.5 3.9-9.9 5.3-2.4 1-5.8-.5-7.2 2.9h-2c-4.3-1.5-11.1 1.1-13 5-4.7 5.7-11.2 3.8-17.1 3.7-2.6.1-5.2-.3-7.9-.6z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M223 318.4c-1.9 3-4.1 5.1-8.2 5.3-4.9.2-6.7 3.6-5.1 8.3 3.1 9.2 6.3 18.3 9.4 27.4-1.7-.6-3.4-1.3-5-1.9-8.6-7-13-16-13-27.2v-7.5c.1-5 .3-5.2 5.5-5.3h12.5c1.3.4 2.6.7 3.9.9z"
        />
        <path
            style={{
                fill: "#ea93a5",
            }}
            d="M241 355.6c2.3-.3 4.7-.7 7-1 1.5 1.7 1.8.4 2-.9.7 0 1.3 0 2-.1 3.7.7 7.3 1.3 11 2 .4 3.2-1.8 4.9-4.2 6-8.3 3.8-17 3.9-25.8 2 .3-.6.8-1.2.8-1.8-.1-4 2-5.4 5.7-5.4.5.1 1-.5 1.5-.8z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M285.9 250.5c.6.6 1.7 1.2 1.7 1.7-.2 1.4-.8 2.9-1.4 4.2-4.2 9.1-6.8 18.6-8.1 28.4-.3 2.1-1 3-3.1 2.8-.4-.6-.8-1.3-1.3-1.9-3-5.6-1.8-11.5-.2-17 1.9-6.8 4.2-13.7 10.5-18 .2.4.3.8.5 1.2.5-.5 1-.9 1.5-1.4h-.1zm-1.8 4.1c-7.4 5.8-11.9 21.2-8 28.5 1.2-4.6 2.2-9.1 3.4-13.4 1.4-5.1 3-10.1 4.6-15.1.1-.1.2-.1.3-.2.1 0 .1-.2.1-.2l-.4.4z"
        />
        <path
            style={{
                fill: "#e68498",
            }}
            d="M241 355.6c-.5.3-1 .9-1.6.9-3.7-.1-5.8 1.4-5.7 5.4 0 .6-.5 1.2-.8 1.8-3.4-.9-6.8-1.7-10.2-2.6.1-.5.1-.9.2-1.4 1.3-1.5 2.6-4.2 4-4.3 4.8-.5 9.5 0 14.1.2z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M205 366.6h5c6.8.3 13.6.6 20.5 1.1 1.2.1 2.4 1.3 3.5 2-9.7 0-19.3-.1-29-.1v-3z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M290.1 237.6c1.9-3.9 8.7-6.5 13-5-4.4 1.7-8.7 3.4-13 5z"
        />
        <path
            style={{
                fill: "#e68498",
            }}
            d="M263 355.6c-3.7-.7-7.3-1.3-11-2 3.7 0 7.4 0 11 .1.1.6 0 1.3 0 1.9z"
        />
        <path
            style={{
                fill: "#333233",
            }}
            d="M273.8 285.7c.4.6.8 1.3 1.3 1.9l.1-.1c-.3 0-.7 0-1 .1-.2-.6-.3-1.3-.4-1.9zM286 250.5c-.5.5-1 .9-1.5 1.4-.2-.4-.3-.8-.5-1.2.7-.1 1.4-.1 2-.2z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M223 359.6c-.1.5-.1.9-.2 1.4-.7-.3-1.3-.7-2-1 .8-.2 1.5-.3 2.2-.4z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M274.1 287.6c.3-.1.6-.1 1-.1l-.6 1.2c-.2-.3-.3-.7-.4-1.1zM292.2 243.9c-.3.2-.7.5-1 .7.3-.2.6-.4 1-.7zM294.1 242.7c-.4.2-.7.5-1.1.7.4-.2.7-.4 1.1-.7z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M136 209.5c3.8 0 7.6 0 11.4.2 1.2.1 2.4.6 3.6 1-3 .3-6.1.8-9.1.9-4.9.3-10 4.7-10.9 9.1-.6 3-1.6 6-3 8.8-6.6 12.9-11.3 26.3-11 41.1-.7-.3-1.3-.7-2-1-.3-.3-.6-.7-.9-1 0-.6-.1-1.3-.1-1.9 1.9-11.2 3.8-22.3-3.1-32.9-2.9-4.4-6.9-8.8-5.9-14.9.4-2.1.5-4.3 1.2-6.3 1.5-4.1 7.6-5.5 13-2.8 4.1 2.1 8.3 3.3 12.9 2.7 1-.1 1.9-1.2 2.8-1.9.5-.3.8-.7 1.1-1.1zM96.1 264.5c-.8-8.2 1.5-16 3.1-23.9.2-.8.5-1.7 1-2.2.9-1 2-1.8 3-2.7l1.8 3.9c-1 3.7-2 7.4-2.9 11.2-.7 2.7-.8 5.6-1.8 8.1-.9 2.1-2.8 3.8-4.2 5.6z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M114.2 268.6c.3.3.6.7.9 1-.3-.4-.6-.7-.9-1zM281.1 214.7c-1.3 0-2.7 0-4 .1-.7.3-1.4.6-2 .9-.7-.3-1.3-.6-2-.9-.3-1.7-.7-3.3-1-5 2.6-5 5.2-10.1 7.9-15.1 0 2.3 0 4.6.2 6.9.2 4.9 2.5 7.3 7.3 7.1 4.6-.1 9.1-.6 13.7-1 4.2 0 8.4 0 12.6-.2 2.8-.1 5.5-.6 8.3-.9.7 0 1.4.1 2.1.1-.6.6-1.1 1.6-1.9 1.8-3.3 1-6.6 1.7-10 2.5-1.8.5-3.6.9-3.3 3.4-5-.3-10-.6-14.9-.9-.4-.5-.8-1.1-1.2-1.6-.2.5-.5 1.1-.7 1.6-1.7 0-3.3.1-5 .1-2.1.4-4.1.8-6.1 1.1z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M327.1 172.6c4.4 5 5.9 10.2.5 16.7 3.3.2 5.8.3 8.4.5.4 1.6.7 3.3 1.1 4.9-5.4 1.7-5.4 1.7-4.1 6.9h-8.9c.4-2.1.4-3.8 1-5.3 1.6-3.9 1.4-4.9-2.7-5.9-2.3-.6-4.9-.5-7.3-.7-1.3 0-2.7-.1-4-.1 0-.3-.1-.6-.1-.9 2.8.3 5-.4 6.6-3 1.9-3.2 4-6.3 6.2-9.3.9-1.4 2.1-2.6 3.3-3.8z"
        />
        <path
            style={{
                fill: "#8bd3c7",
            }}
            d="M311 189.6c1.3 0 2.7.1 4 .1.1 2.7 1.9 5.5-1.2 8-1.8 1.5-.4 3.3 1.8 3.8 2.3.5 4.7 1 7.2 1.5-.3 1.4-.6 2.5-.8 3.6-2.8.3-5.5.8-8.3.9-4.2.2-8.4.1-12.6.2-.6-1.5-1.2-2.9-1.4-3.5 4-5.2 7.7-9.9 11.3-14.6z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M309 214.5c-.3-2.5 1.5-3 3.3-3.4 3.3-.9 6.7-1.6 10-2.5.7-.2 1.3-1.2 1.9-1.8h3c1.7.3 3.3.7 5 1-.9.9-1.7 2-2.8 2.5-1.1.5-2.6 0-3.7.3-4 1.1-7.7 3.6-12.2 2.3-.6-.2-1.5 1.2-2.3 1.9-.9-.2-1.5-.3-2.2-.3z"
        />
        <path
            style={{
                fill: "#dec3c9",
            }}
            d="M347.4 201.6c-2.6-4.5-5.6-8.9-4.9-15.7 5.8 4.6 6.8 9.9 6.7 15.7h-1.8z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M332.9 201.5c-1.2-5.3-1.2-5.3 4.1-6.9l3 6.9h-7.1z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M332.1 207.6c-1.7-.3-3.3-.7-5-1 2.7-.3 5.4-.6 8-1-.3.6-.7 1.3-1 1.9-.7.1-1.4.1-2 .1z"
        />
        <path
            style={{
                fill: "#8bd3c7",
            }}
            d="M327.1 172.6c-1.1 1.2-2.4 2.4-3.3 3.7-2.2 3-4.3 6.1-6.2 9.3-1.6 2.6-3.8 3.3-6.6 3-1.3-2.6-2.7-5.2-4-7.8 4.7-3.9 8.5-8.4 11.1-14 .7-1.4 2.5-2.2 3.9-3.3 1.6 3.1 3.3 6.1 5.1 9.1z"
        />
        <path
            style={{
                fill: "#2a2828",
            }}
            d="m188.9 247.6 4.1 4.1c.2 2.1.4 4.2.8 6.3 1.7 8.8 3.4 17.6 5.4 26.4.7 3.1 0 7.3 4.7 8.2-3 12.7-14 22.1-26.9 22.6-1.3 0-2.6.1-4 .2-8.4-1.4-15.7-5.1-22-10.7-8.7-9.7-13.6-20.7-12.9-34 .4-6.7 3-12.5 6-18.3.2-.3.4-.5.7-.8 1.1-1.1 2.1-2.2 3.2-3.3 5.3-3.8 10.6-7.7 17.4-7.8 3.9-.1 7.8.4 11.8.7 2.7 1.3 5.3 2.5 8 3.8.3.2.6.5.9.7l.1.1c.6.3 1.3.6 1.9.9l.1.1c.1.2.4.5.7.8zm3.1 31-.3-2.1c-1.4-5.3-4-9.8-8.5-13.1-6.9-5.1-17.3-3.3-21 3.6-4.5 8.4-2.1 19.4 5.5 25.1 9.6 7.2 21.4 2.7 23.6-9.1.3-1.4.5-2.9.7-4.4z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M117 270.6c-.4-14.8 4.3-28.2 11-41.1 1.4-2.7 2.4-5.8 3-8.8.9-4.5 6-8.9 10.9-9.1 3.1-.2 6.1-.6 9.1-.9 1.7-.1 3.4-.1 5-.2 1 .4 2 .7 3 1.1.6.7 1.1 1.7 1.8 1.9 2.4.5 4.8 1 7.2 1 3 0 6-.6 9-.9l43 .9c2.6 3.4 5.8 2.5 9 1l30 .3c-.8 4.8-5 3.1-7.5 3.3-9 .5-18.1.4-27.2.3-2.8 0-5.6-.8-8.4-.7-12.7.4-25.5.6-38.2 1.5-5.5.4-5.3 1.1-3 6.1.7 1.6.2 3.7.2 5.5 0 1.5-.1 3.1-.1 4.6-1.6-.2-3.3-.2-4.9-.5-5.6-1.3-11.2-3-16.8-4.2-9.9-2.2-15.5.6-18.2 8.7-1.8 4.2-3.7 8.4-5.3 12.7-1.5 3.9-2.5 7.9-4 11.8-1.9 5.6-2.1 5.7-8.6 5.7z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M135.1 240.6c2.7-8.1 8.3-10.9 18.2-8.7 5.6 1.2 11.2 2.9 16.8 4.2 1.6.4 3.3.4 4.9.5v1c.7 1.2 1.3 2.3 2 3.5-3.9-.2-7.8-.8-11.8-.7-6.8.2-12.1 4-17.4 7.8-3.8-.8-5.3-3.1-4.9-6.9.1-1.5-.5-3-.8-5-2.8 1.8-4.9 3-7 4.3zM116.1 368.6c-.4-4.7-.7-9.3-1.1-14 5.2 3.3 6.2 8.4 6.1 14h-5zM144.7 251.6c-.2.3-.4.5-.7.8.3-.3.5-.5.7-.8z"
        />
        <path
            style={{
                fill: "#333233",
            }}
            d="M203.9 292.6c-4.7-.9-4-5.1-4.7-8.2-2-8.7-3.7-17.6-5.4-26.4-.4-2.1-.5-4.2-.8-6.3 5.2 4.4 8.4 9.9 9.5 16.7.7 4.1 1.9 8.1 2.1 12.2.3 4-.4 8-.7 12z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M175 237.6v-1c1.3.6 3.5-2.7 4 1h-4z"
        />
        <path
            style={{
                fill: "#333233",
            }}
            d="M187.9 246.7c-.6-.3-1.3-.6-1.9-.9.6.3 1.3.6 1.9.9zM186 245.6c-.3-.2-.6-.5-.9-.7.3.2.6.5.9.7zM188.9 247.6l-.9-.9.9.9z"
        />
        <path
            style={{
                fill: "#2a2828",
            }}
            d="M153.8 206.7c-1.6-.1-3.1-.2-4.7-.4-.5-.2-1-.4-1.5-.7h-.3l-.3-.1c-.6-1-1.2-1.9-2.5-3.9 2.6-.4 4.9-1.5 6.6-.9 4.8 1.8 8.8 1.6 12-2.8.3-.4 1.2-.4 1.8-.5-1.8 5.4-5.6 8.4-11.1 9.3z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M167.1 189.6c.9-1.5 1.7-2.9 2.6-4.4.1.2.2.3.4.4v-.1c-.4 1.8.3 2.4 2 1.8v3.8c-1.1.2-2.1.4-3.2.7-.6-.7-1.2-1.5-1.8-2.2z"
        />
        <path
            style={{
                fill: "#2a2828",
            }}
            d="M167.1 189.6c.6.7 1.2 1.5 1.8 2.2.2.1.3.3.2.6-.5.6-1 1.2-1.5 1.9 0 1 0 2.1.1 3.1-.9.1-1.8.2-2.6.3.6-2.8 1.3-5.4 2-8.1z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M173.5 181.6h.3c.2 1.7 1 3.7-1.7 4.3-.4-.2-.7-.3-1.1-.5-.3-.6-.6-1.2-.8-1.8-.2-.6-.2-1.3-.2-2h3.5z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M142.2 184.6c-1.1-1.7-2.1-3.4-3.2-5 1.9 1.2 5.5 1.2 3.2 5z"
        />
        <path
            style={{
                fill: "#e8c7cf",
            }}
            d="M173.5 181.6h-3.6c.2-.2.2-.4.3-.7 1.1.2 2.2.4 3.3.7z"
        />
        <path
            style={{
                fill: "#dec3c9",
            }}
            d="M171 185.4c.4.2.7.3 1.1.5v1.5c-1.7.5-2.4 0-2-1.8.3-.1.6-.2.9-.2z"
        />
        <path
            style={{
                fill: "#2a2828",
            }}
            d="M144 168.6c.3.4.5.7.8 1.1-.2-.4-.5-.7-.8-1.1z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M170.1 185.6c-.2-.1-.3-.2-.4-.4.2.1.3.2.4.4z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="m246.5 151.6-6.3 3.3c-.4.5-.8 1-1.2 1.6l-.1.1c-5 .9-7 5.2-9.2 8.9-2.4 4.1-3.8 8.7-5.6 13.1-1.1.9-2.6 1.6-3.2 2.7-2.2 4-5.4 6.2-9.9 6.4-6.3.2-12.7.1-19.1.1-1.7 0-3.4-.1-5.1-.2-.3 0-.6 0-.9-.1-1.8.1-3.2-.3-3.1-2.7.1-2.1 0-4.2 0-6.3 8.2 3.3 13.5-1.9 18.9-6.5.8-.7.8-2.4 1.2-3.6 9.3-.3 17.3-6.8 18.9-14 .5-2.3 1.3-4.6 2-6.9 2.4-.4 4.9-.4 7.2-1.3 4.1-1.5 7.3-.4 10.4 2.2 1.7 1.4 3.5 2.2 5.1 3.2z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M224 147.6c-.7 2.3-1.5 4.5-2 6.9-1.6 7.2-9.6 13.7-18.9 14-8.7.2-15.1-3.9-20-10.9-.4-1.6-.8-3.3-1.3-4.9-.3-2.2 1.5-4.9-1.6-6.3v-1.6c.6-1.2 1.1-2.3 1.8-3.5.7-1.3 1.5-2.6 2.2-3.9.2 2.4.5 4.8.7 7.1 0 8.6 5.1 15.1 13.3 16.9 12.7 2.6 19.9-4.4 22-11.5 2-7 .3-13.5-3.4-19.5.4-.3.8-.7 1.2-1 1.5 2.1 3 4.1 4.6 6.2.5 4.1 1 8.1 1.4 12z"
        />
        <path
            style={{
                fill: "#a16d78",
            }}
            d="M167.7 197.3c0-1 0-2.1-.1-3.1 1.4.1 2.8.2 4.3.3.4-.3.7-.6 1.1-.9 3.3-3 2.6-7 3-10.8.7-.4 1.3-.8 2-1.2.1 5.9.8 11.9-4 16.7-2.1-.2-4.2-.6-6.3-1z"
        />
        <path
            style={{
                fill: "#d4a6b1",
            }}
            d="M174 198.4c4.7-4.7 4-10.8 4-16.7l1.8-2.1c-.3 5.3-.7 10.5-1 15.8-.9 2-1.8 3.9-2.7 5.9-.8-1-1.4-1.9-2.1-2.9z"
        />
        <path
            style={{
                fill: "#e8c7cf",
            }}
            d="M175.9 182.9c-.4 3.8.3 7.8-3 10.8-.3-.1-.6-.1-.9-.1-.2-.2-.2-.5-.2-.8.1-.6.2-1.1.3-1.7v-5.3c2.6-.6 1.9-2.6 1.7-4.3.7.5 1.4 1 2.1 1.4z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M186.1 187.6c.3 0 .6 0 .9.1 2.1.6 4.2 1.8 6.4 1.8 7.1.3 14.3.2 21.4.3 3.1 1.3 6.2 2.5 9.2 3.8 2.8 6.3 5.6 12.6 8.8 19.7 1.3-1.7 2-2.7 2.8-3.7h2.4c2.3.4 4.7.7 7 1.1 4.9-.1 9.8-.1 14.8-.2 2-.6 4-1.3 6.1-1.9 1.1.6 2.1 1.2 3.2 1.8l.4.1h.5c.3-.2.7-.5 1-.7.3 0 .6-.1 1-.1.3 1.7.7 3.3 1 5-1 .1-2 .2-2.9.3-2.4.2-4.7.4-7.1.6-.7.1-1.3.1-2 .2s-1.3.1-2 .2l-30-.3c-2.9-1.7-5.7-3.1-9-1l-43-.9c-1.4-1.2-2.7-2.3-4.1-3.5.3-.5.7-1.1 1-1.6 4-2.8 5.5-7.1 6.3-11.6.6-1.3 1-2.7 1.8-3.9 1.3-2.1 2.8-3.8 4.1-5.6z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M180.3 196.8c-.8 4.6-2.2 8.8-6.3 11.6.7-2.4 1.4-4.8 2-7.2.9-2 1.8-3.9 2.7-5.9.5.6 1.1 1.1 1.6 1.5z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M176 201.3c-.7 2.4-1.4 4.8-2 7.2-.3.5-.7 1.1-1 1.6-.5 0-1.1.1-1.6.1h-2.6c-.5 0-1.1-.1-1.6-.1-2.3-.7-4.7-1.3-7-2-2.1-.5-4.2-.9-6.2-1.4 5.4-1 9.2-3.9 11.2-9.1.9-.1 1.8-.2 2.6-.3 2.1.4 4.2.7 6.3 1.1.6 1 1.2 1.9 1.9 2.9z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M160.1 208.1c2.3.7 4.7 1.3 7 2l-8.1 1.5c-1-.4-2-.7-3-1.1 1.4-.8 2.7-1.6 4.1-2.4z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M147.7 205.7c.5.2 1 .4 1.5.7-.5-.2-1-.5-1.5-.7z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M218.1 129.5c-.4.3-.8.7-1.2 1-9.5-11.1-29.8-8.5-32.6 6.9-.7 1.3-1.5 2.6-2.2 3.9-.6 1.1-1.2 2.3-1.8 3.5-1.2-10 3.7-16.8 11.9-21.5 5-2.9 16.8-3.3 23.9 3 .9.8 1.3 2.1 2 3.2zM180.3 146.4c3.1 1.5 1.3 4.2 1.6 6.3-3.5-1.3-1.2-4.1-1.6-6.3z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M266.2 156.6c1.9 1.3 3.9 2.7 5.8 4 .7 1.4 1.4 2.7 2.2 4.1-.2.3-.2.6-.2.9-1.5 4.6-2.6 9.3-.9 14.2-.4.6-.7 1.2-1.1 1.7-2.4-1.4-.5-5.5-3.9-6.2.2-4.3-3-4.3-5.9-4.7-.4-.3-.7-.5-1.1-.8-.9-.9-1.7-1.8-2.6-2.7 0-.3 0-.6.1-.9-.1.3-.2.5-.3.8-.4 0-.8.1-1.2.1-.6-2.4-5.1-2-4-5.6.8-.3 1.6-.7 2.4-1-2.8-.9-5.7-1.9-8-1.2-6.2 1.9-11.1 5.8-14 11.8-3 6.1-4 12.6-3.5 19.4h-1.9c-.1-.2-.1-.5-.1-.7-.1-.4-.3-.8-.4-1.2 0-1.7-.1-3.3-.1-5 .2-1.1.4-2.1.5-3.2 1.7-8.5 4.6-16.3 12-21.7 1.8-1 3.6-2 5.5-2.9 3.5 0 7.1-.7 10.4.1 3.1.7 5.9 3 8.9 4.6.4-1.5.9-2.7 1.4-3.9z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M239.9 158.7c-7.4 5.4-10.3 13.2-12 21.7-.2 1.1-.4 2.1-.5 3.2 0 1.7.1 3.3.1 5 .1.4.3.8.4 1.2 0 .3 0 .5.1.7.7 5 1 10.1 5.7 13.4.6 1.9 1.3 3.8 1.9 5.7-.7 1-1.5 2-2.8 3.7-3.2-7.1-6-13.4-8.8-19.7 0-1.3 0-2.7-.1-4 0-3.7.1-7.3.1-11 1.8-4.4 3.2-9 5.6-13.1 2.1-3.7 4.2-8 9.2-8.9.5.7.8 1.4 1.1 2.1zM266.2 156.6c-.5 1.2-1 2.5-1.6 3.9-3-1.6-5.7-3.8-8.9-4.6-3.3-.8-6.9-.1-10.4-.1 3.8-2.5 7.8-2.5 12-1.5 2.5.6 5.1.8 7.7 1.2.5.3.8.7 1.2 1.1zM239.1 156.5z"
        />
        <path
            style={{
                fill: "#67b7a8",
            }}
            d="M281.4 121.6c7.9 0 15.3-.2 22.7.1 3.6.1 5.8 2.9 5.9 6.2.1 3.5-2.3 6.5-6 6.6-7.4.2-14.8.1-22.6.1v-13zm24.6 4.9c-1.1-.9-2.2-2.6-3.4-2.6-6.4-.3-12.8-.1-19.6-.1v5.6c-.1 2.6 1.8 2.2 3.4 2.3 3.9.3 7.8.7 11.7.9 3.2.1 6.7.8 9-2.5 1.2-1.8 1.5-3.1-1.1-3.6zM298 164.8c.6 1.7 1.2 3.4 1.8 5-.6.3-1.3.6-1.9.8-1.6-2.7-3.3-5.4-4.8-8.1-3.2-5.6-3.8-5.8-11.3-3.9.4 4.2.8 8.6 1.2 12.9l-.9 18c-.6 0-1.1-.1-1.7-.1l-.3-13.8c0-3.4 0-6.7.1-10.1v-1c.1-9.4 1.2-10.4 10.6-8.8 1.7.3 3.1 2.5 4.4 4 1 1.6 1.8 3.4 2.8 5.1z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M273.1 179.8c-1.7-4.9-.6-9.5.9-14.2h1c.2.2.4.2.7.2l.3 1.8c.3 1.4.5 2.9.9 4.3 2 6.8 2.8 13.7.8 20.7-.4-5.1-.9-10.2-1.3-15.7-1.5 1.2-2.4 2-3.3 2.9z"
        />
        <path
            style={{
                fill: "#95d9cd",
            }}
            d="m276.1 167.5-.3-1.8c1.4-.1 2.9-.1 4.3-.2 0 3.4 0 6.7-.1 10.1-1.3-2.6-2.6-5.4-3.9-8.1zM275 165.6h-1c0-.3 0-.6.2-.9.3.3.6.6.8.9z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M297.9 170.6c.6-.3 1.3-.6 1.9-.8 2.4 3.7 4.7 7.4 7.1 11.1 1.3 2.6 2.7 5.2 4 7.8 0 .3 0 .6.1.9v.1h-3.1c-1-2-2-4-3-5.9-2.3-4.1-4.6-8.1-7-12.2.1-.3.1-.6 0-1z"
        />
        <path
            style={{
                fill: "#e1e0e0",
            }}
            d="M320.1 277c-.4 6.2-3.5 12.2-10.2 15.9-9.2 5.1-19.8-.2-21.7-10.6-1.7-8.9 3.6-17.4 10.6-21 10.5-5.4 21.5 2 21.3 15.7z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M263 215.5c2.4-.2 4.7-.4 7.1-.6-.7.9-1.3 2.3-2.2 2.5-1.5.4-3.3.1-4.9.1v-2zM281.1 214.7l6-.9c-1.6 3.2-3.8 1.9-6 .9z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M263 215.5v2.1c-.7-.6-1.3-1.3-2-1.9.7-.1 1.4-.1 2-.2z"
        />
        <path
            style={{
                fill: "#cfcece",
            }}
            d="M292.1 213.6c.2-.5.5-1.1.7-1.6.4.5.8 1.1 1.2 1.6h-1.9z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M275 215.6c.7-.3 1.4-.6 2-.9-.6.3-1.3.6-2 .9z"
        />
        <path
            style={{
                fill: "#e68498",
            }}
            d="M250 353.7c-.2 1.3-.5 2.6-2 .9.7-.3 1.4-.6 2-.9z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M284 254.6c-1.5 5-3.1 10-4.5 15.1-1.2 4.4-2.3 8.8-3.4 13.4-3.9-7.3.5-22.6 7.9-28.5.1 0 0 0 0 0zM284.1 254.6l.4-.4c0 .1 0 .2-.1.2-.1.1-.3.1-.3.2z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M307.9 189.7h3.1c-3.6 4.7-7.3 9.4-11.3 14.5l1.4 3.5c-4.6.3-9.1.9-13.7 1-4.8.1-7.1-2.2-7.3-7.1-.1-2.3-.1-4.6-.2-6.9.1-1.7.3-3.4.4-5 .6 0 1.1.1 1.7.1 8.7-.2 17.3-.1 25.9-.1z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M280.3 189.5c-.1 1.7-.3 3.4-.4 5-2.6 5-5.2 10.1-7.9 15.1-.3 0-.6.1-1 .1l-.9-.3c2.5-5.7 5.1-11.3 7.6-17 1.9-7 1.1-13.8-.8-20.7-.4-1.4-.6-2.9-.9-4.3 1.3 2.7 2.6 5.5 3.9 8.2.2 4.7.3 9.3.4 13.9z"
        />
        <path
            style={{
                fill: "#d8d8d8",
            }}
            d="M192 278.6c-.2 1.5-.4 3-.6 4.4-2.2 11.8-14 16.3-23.6 9.1-7.6-5.7-10-16.7-5.5-25.1 3.7-6.9 14.1-8.7 21-3.6 4.5 3.3 7.1 7.8 8.5 13.1-.1.8.1 1.4.2 2.1z"
        />
        <path
            style={{
                fill: "#333233",
            }}
            d="m192 278.6-.3-2.1c0 .8.2 1.4.3 2.1z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="m159 211.6 8.1-1.5c.5 0 1.1.1 1.6.1h2.6c.5 0 1.1-.1 1.6-.1 1.4 1.2 2.7 2.3 4.1 3.5-3 .3-6 .9-9 .9-2.4 0-4.9-.5-7.2-1-.7-.2-1.2-1.3-1.8-1.9zM220 214.5c3.3-2.1 6.2-.6 9 1-3.2 1.5-6.3 2.5-9-1z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M172.1 191.2c-.1.6-.2 1.1-.3 1.7-.9-.2-1.7-.3-2.6-.5 0-.2-.1-.4-.2-.6 1-.2 2-.4 3.1-.6z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M169.2 192.4c.9.2 1.7.3 2.6.5 0 .3.1.5.2.8-.2.3-.3.6-.1.9-1.4-.1-2.8-.2-4.3-.3.5-.7 1.1-1.3 1.6-1.9z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M224.2 178.6c0 3.7-.1 7.3-.1 11-3.1.1-6.1.2-9.2.2-7.1-.1-14.3 0-21.4-.3-2.2-.1-4.3-1.2-6.4-1.8 1.7.1 3.4.2 5.1.2 6.4 0 12.7.1 19.1-.1 4.4-.2 7.7-2.4 9.9-6.4.4-1.2 1.9-1.9 3-2.8z"
        />
        <path
            style={{
                fill: "#e68498",
            }}
            d="M184.3 137.5c2.8-15.5 23.1-18 32.6-6.9 3.7 6.1 5.4 12.5 3.4 19.5-2 7-9.2 14.1-22 11.5-2.2-7.4-6.8-12.9-13.3-16.9-.2-2.5-.5-4.8-.7-7.2z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M185 144.6c6.5 4 11.1 9.5 13.3 16.9-8.1-1.8-13.3-8.3-13.3-16.9z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M171.9 194.6c-.1-.3-.1-.6.1-.9.3 0 .6 0 .9.1-.3.2-.7.5-1 .8z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M262.2 170.6c2.9.4 6.2.4 5.9 4.7 3.4.7 1.5 4.8 3.9 6.2.4.7.9 1.4 1.3 2.1-.4 4.3-.8 8.6-1.3 12.9l-.1.1c-.6 1.3-1.3 2.6-1.9 3.9l-.1.1c-1 1.3-2 2.6-2.9 3.9l-.1.1c-2.4 2-4.8 3.9-7.2 5.9-4.9.1-9.8.1-14.8.2-2.3-1.4-4.7-2.7-7-4.1v-.1l-1-1c-1-1.7-2-3.3-3-5-.7-3-1.3-6-2-9.1 1.2-.3 2.5-.6 3.8-.9.4 1.8.7 4 1.4 6 1.3 3.8 3.4 4.9 7 3.9l1 1c.1 1.3-.2 3.2.4 3.7 5.2 3.3 17.2-.8 19.2-6.4.2-.4-.9-1.3-1.4-1.9.5-.7 1.1-1.4 1.6-2.1.5.2 1 .4 1.4.6.1-.3.1-.5.2-.8-.5.1-1.1.1-1.6.2 2.9-6.6 2.8-12.8-2.9-18 .2-2.2.2-4.2.2-6.1z"
        />
        <path
            style={{
                fill: "#e2667a",
            }}
            d="M273.3 183.6c-.4-.7-.9-1.4-1.3-2.1.4-.6.7-1.2 1.1-1.7.9-.8 1.8-1.6 3.3-2.9.5 5.4.9 10.6 1.3 15.7-2.5 5.7-5.1 11.3-7.6 17l-.2.3c-.3.2-.5.4-.8.6-1.1-.6-2.1-1.2-3.2-1.8.4-1.3.7-2.6 1.1-4l.1-.1c2.7 0 3.1-1.7 2.9-3.9l.1-.1c.6-1.3 1.3-2.6 1.9-3.9l.1-.1c1-3.2 2.2-6.3 2.9-9.5.1-1.1-1.1-2.3-1.7-3.5z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M214.9 189.9c3.1-.1 6.1-.2 9.2-.2 0 1.3 0 2.7.1 4-3.2-1.3-6.2-2.6-9.3-3.8z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M238.1 206.6c2.3 1.4 4.7 2.7 7 4.1-2.3-.4-4.7-.7-7-1.1v-3zM267 204.6c-.4 1.3-.7 2.6-1.1 4-2 .6-4 1.3-6.1 1.9 2.4-1.9 4.8-3.9 7.2-5.9z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M238.1 206.6v3h-2.4c-.6-1.9-1.3-3.8-1.9-5.7.2-.2.3-.4.5-.5.9.7 1.8 1.4 2.8 2.2.3.3.6.7 1 1z"
        />
        <path
            style={{
                fill: "#bdbdbd",
            }}
            d="M270.2 209.5c.3.1.6.1.9.3-.3.2-.7.5-1 .7 0-.2-.1-.5-.1-.7l.2-.3z"
        />
        <path
            style={{
                fill: "#ddb4be",
            }}
            d="M269.9 209.8c.1.2.1.5.1.7h-.5l-.4-.1c.3-.2.6-.4.8-.6z"
        />
        <path
            style={{
                fill: "#97a9a2",
            }}
            d="M171.3 210.2h-2.6 2.6z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M246.3 171.7c-.5 1.2-1 2.4-1.5 3.5-1.6 1.5-3.1 3-4.7 4.5-.4-.4-1.1-1.3-1.3-1.2-1 .5-2 1.3-2.9 1.9-.3-.2-.6-.5-.8-.7 1.5-2.7 2.4-6.6 4.8-8 3.6-2.1 4-5.2 5-8.3 2.1-3.5 5.2-2.6 8.2-1.9-1 3.6 3.5 3.2 4 5.6-3.5.8-7 1.7-10.5 2.5h-1.9c0 .2-.1.4-.1.5.6.2 1.1.4 1.7.6.1.4.1.7 0 1z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M253.1 161.5c-3-.7-6.1-1.6-8.2 1.9-2 1.3-4.2 2.3-5.9 3.9-7 6.7-8 15.2-6.9 24.2.7 3 1.3 6 2 9.1-1.4-3.4-2.8-6.7-4.2-10.1-.5-6.8.5-13.2 3.5-19.4 3-5.9 7.8-9.9 14-11.8 2.4-.7 5.3.3 8 1.2-.7.4-1.5.7-2.3 1z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M246.3 171.7v-.9c.1-.4.2-.7.3-1.1 3.5-.8 7-1.7 10.5-2.5.4 0 .8-.1 1.2-.1l.2.1c.9.9 1.7 1.8 2.6 2.7-1 .6-2 1.8-3.1 1.9-3.8.1-7.7-.1-11.7-.1z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M229.9 190.5c1.4 3.4 2.8 6.7 4.2 10.1.1.9.2 1.9.2 2.8-.2.2-.4.3-.5.5-4.7-3.3-5-8.4-5.7-13.4h1.8zM227.4 183.6c.2-1.1.4-2.1.5-3.2-.2 1.1-.3 2.1-.5 3.2zM258.5 167.1l-.2-.1c.1-.3.2-.5.3-.8 0 .3-.1.6-.1.9zM227.9 189.8c-.1-.4-.3-.8-.4-1.2.1.4.3.8.4 1.2z"
        />
        <path
            style={{
                fill: "#e68498",
            }}
            d="M283.1 129.4v-5.6c6.7 0 13.1-.1 19.6.1 1.2 0 2.3 1.7 3.4 2.6-1.2 2.3-3 3.1-5.6 3-5.9-.2-11.6-.1-17.4-.1z"
        />
        <path
            style={{
                fill: "#4d8378",
            }}
            d="M283.1 129.4c5.8 0 11.5-.1 17.3.1 2.6.1 4.4-.8 5.6-3 2.5.5 2.3 1.8 1.2 3.5-2.3 3.4-5.7 2.7-9 2.5-3.9-.2-7.8-.6-11.7-.9-1.6 0-3.5.4-3.4-2.2z"
        />
        <path
            style={{
                fill: "#7c8281",
            }}
            d="M307.9 189.7H282l.9-18h15c2.3 4.1 4.7 8.1 7 12.2 1.1 1.8 2 3.8 3 5.8z"
        />
        <path
            style={{
                fill: "#e68498",
            }}
            d="M297.9 171.6h-15c-.4-4.4-.8-8.7-1.2-12.9 7.6-1.9 8.1-1.7 11.3 3.9l4.8 8.1c.2.3.2.6.1.9z"
        />
        <path
            style={{
                fill: "#e4e4e4",
            }}
            d="M248 200.3c2.9-5.4 2.6-11.3 2.8-17.1.1-4.5-.4-4.4 4.2-4.6 2.5-.1 5.4 1.1 7.1-2 5.7 5.3 5.8 11.4 2.9 18-.5.7-1.1 1.4-1.6 2.1-.2 0-.3.1-.3.3-.3.2-.5.4-.8.6-4.3 3.5-8.9 5.1-14.3 2.7z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M232.1 191.5c-1.1-9-.1-17.5 6.9-24.2 1.7-1.6 3.9-2.6 5.9-3.9-1 3.1-1.5 6.2-5 8.3-2.3 1.4-3.2 5.2-4.8 8 .3.2.6.5.8.7 1-.7 1.9-1.4 2.9-1.9.2-.1.9.8 1.3 1.2-2 6.3-2.4 12.3 2.6 17.7.7.8 1 2 1.5 3-3.6 1-5.7 0-7-3.9-.7-2-1-4.2-1.4-6-1.3.5-2.5.8-3.7 1z"
        />
        <path
            style={{
                fill: "#e4e4e4",
            }}
            d="M244.2 200.5c-.5-1-.8-2.2-1.5-3-4.9-5.3-4.6-11.4-2.6-17.7 1.6-1.5 3.1-3 4.7-4.5.4 8.4.8 16.8 1.3 25.2l-.9.9c-.4-.3-.7-.6-1-.9z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="m245.2 201.4.9-.9c.7-.1 1.3-.2 2-.2 5.4 2.5 10 .8 14.3-2.6.3-.2.5-.4.8-.6 0-.2.1-.3.3-.3.5.7 1.5 1.5 1.4 1.9-1.9 5.6-14 9.7-19.2 6.4-.8-.4-.4-2.4-.5-3.7z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="M273.3 183.6c.6 1.2 1.8 2.4 1.6 3.4-.7 3.2-1.9 6.3-2.9 9.5.5-4.3.9-8.6 1.3-12.9zM234.3 203.4c-.1-.9-.2-1.9-.2-2.8 1 1.7 2 3.3 3 5-1-.7-1.9-1.5-2.8-2.2zM270 200.6c.2 2.2-.2 3.9-2.9 3.9 1-1.2 1.9-2.6 2.9-3.9zM272 196.6c-.6 1.3-1.3 2.6-1.9 3.9.6-1.3 1.2-2.6 1.9-3.9z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M265 194.6c.5-.1 1.1-.1 1.6-.2-.1.3-.1.5-.2.8-.4-.2-.9-.4-1.4-.6z"
        />
        <path
            style={{
                fill: "#c38f9b",
            }}
            d="m238 206.6-1-1c.4.3.7.7 1 1z"
        />
        <path
            style={{
                fill: "#57461e",
            }}
            d="M246.6 169.7c-.1.4-.2.7-.3 1.1-.6-.2-1.1-.4-1.7-.6 0-.2.1-.4.1-.5h1.9z"
        />
        <path
            style={{
                fill: "#7a6632",
            }}
            d="M263.1 197.1c-.3.2-.5.4-.8.6.3-.2.5-.4.8-.6z"
        />
    </svg>
)

export default SvgComponent
