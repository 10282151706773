import React from 'react'
import styled from 'styled-components'
import { CheckmarkIcon, CloseIcon, LinkExternal } from 'fortcake-uikit-v2'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { getBscScanLink } from '../../../utils'
import { TransactionDetails } from '../../../state/transactions/reducer'
import CircleLoader from '../../Loader/CircleLoader'

const TransactionState = styled.div<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.primary};
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) =>
        pending ? theme.colors.primary : success ? theme.colors.success : theme.colors.failure};
`

export default function Transaction({ tx }: { tx: TransactionDetails }) {
    const { chainId } = useActiveWeb3React()

    const summary = tx?.summary
    const pending = !tx?.receipt
    const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

    if (!chainId) return null

    return (
        <TransactionState pending={pending} success={success}>
            <LinkExternal href={getBscScanLink(tx.hash, 'transaction', chainId)}>{summary ?? tx.hash}</LinkExternal>
            <IconWrapper pending={pending} success={success}>
                {pending ? <CircleLoader /> : success ? <CheckmarkIcon color="success" /> : <CloseIcon color="failure" />}
            </IconWrapper>
        </TransactionState>
    )
}
