const gamePricess = [
    {
        address: '0x2f477a472f4657f7917126a663b5affe94d5a2b6',
        price: {
            bnb: 0.00,
            usd: 0.00,
        },
    },
];

export default gamePricess
