import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Trade, TradeType } from 'fortcake-sdk'
import { Button, Text, AutoRenewIcon } from 'fortcake-uikit-v2'
import { useTranslation } from '../../../contexts/Localization'
import { Field } from '../../../state/swap/actions'
import {
    computeSlippageAdjustedAmounts,
    computeTradePriceBreakdown,
    formatExecutionPrice,
    warningSeverity,
} from '../../../utils/prices'
import { AutoColumn } from '../../../components/Layout/Column'
import QuestionHelper from '../../../components/QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../../../components/Layout/Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'

const SwapModalFooterContainer = styled(AutoColumn)`
  margin-top: 24px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.default};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.background};
`

export default function SwapModalFooter({
    trade,
    onConfirm,
    allowedSlippage,
    swapErrorMessage,
    disabledConfirm,
}: {
    trade: Trade
    allowedSlippage: number
    onConfirm: () => void
    swapErrorMessage: string | undefined
    disabledConfirm: boolean
}) {
    const { t } = useTranslation()
    const [showInverted, setShowInverted] = useState<boolean>(false)
    const slippageAdjustedAmounts = useMemo(
        () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
        [allowedSlippage, trade],
    )
    const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
    const severity = warningSeverity(priceImpactWithoutFee)

    return (
        <>
            <SwapModalFooterContainer>
                <RowBetween align="center">
                    <Text fontSize="14px">{t('Price')}</Text>
                    <Text
                        fontSize="14px"
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'right',
                            paddingLeft: '10px',
                        }}
                    >
                        {formatExecutionPrice(trade, showInverted)}
                        <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
                            <AutoRenewIcon width="14px" />
                        </StyledBalanceMaxMini>
                    </Text>
                </RowBetween>

                <RowBetween>
                    <RowFixed>
                        <Text fontSize="14px">
                            {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}
                        </Text>
                        <QuestionHelper
                            text={t(
                                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
                            )}
                            ml="4px"
                        />
                    </RowFixed>
                    <RowFixed>
                        <Text fontSize="14px">
                            {trade.tradeType === TradeType.EXACT_INPUT
                                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
                        </Text>
                        <Text fontSize="14px" marginLeft="4px">
                            {trade.tradeType === TradeType.EXACT_INPUT
                                ? trade.outputAmount.currency.symbol
                                : trade.inputAmount.currency.symbol}
                        </Text>
                    </RowFixed>
                </RowBetween>
                <RowBetween>
                    <RowFixed>
                        <Text fontSize="14px">{t('Price Impact')}</Text>
                        <QuestionHelper
                            text={t('The difference between the market price and your price due to trade size.')}
                            ml="4px"
                        />
                    </RowFixed>
                    <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
                </RowBetween>
                <RowBetween>
                <RowFixed>
                    <Text $contrast={1} fontSize="14px">
                        {t('Liquidity Provider Fee')}
                    </Text>
                    <QuestionHelper
                        text={
                            <>
                            <Text mb="12px">{t('For each trade a %amount% fee is paid', { amount: '0%' })}</Text>
                            </>
                        }
                        ml="4px"
                        placement="top-start"
                    />
                </RowFixed>
                <Text $contrast={2} fontSize="14px">
                    {`0 ${trade.inputAmount.currency.symbol}`}
                </Text>
            </RowBetween>
            </SwapModalFooterContainer>

            <AutoRow>
                <Button
                    variant={severity > 2 ? 'danger' : 'primary'}
                    onClick={onConfirm}
                    disabled={disabledConfirm}
                    mt="12px"
                    id="confirm-swap-or-send"
                    width="100%"
                >
                    {severity > 2 ? t('Swap Anyway') : t('Confirm Swap')}
                </Button>

                {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            </AutoRow>
        </>
    )
}
