import * as React from 'react'
import { Svg } from 'fortcake-uikit-v2'

const SvgComponent = (props: any) => (
    <Svg {...props} viewBox="0 0 339.033 339.033">
        <circle
            style={{
                fill: 'transparent',
            }}
            cx={169.517}
            cy={169.517}
            r={162.016}
        />
        <path d="M169.517 0C76.045 0 .001 76.045.001 169.517c0 93.471 76.044 169.516 169.516 169.516s169.516-76.044 169.516-169.516S262.989 0 169.517 0zm-26.15 17.224c-13.76 11.78-26.933 26.445-37.516 42.056a204.917 204.917 0 0 0-12.856 21.737H42.93c23.204-33.091 59.033-56.705 100.437-63.793zM33.629 96.017H86.09c-8.754 21.293-13.708 43.833-14.594 66.79h-56.34c1.034-24.09 7.601-46.77 18.473-66.79zm-18.404 81.79H71.49c.863 23.317 5.768 45.651 14.643 66.79H34.511a153.414 153.414 0 0 1-19.286-66.79zm28.826 81.789h49.157a205.148 205.148 0 0 0 12.671 20.988c12.478 18.143 25.89 31.638 37.218 41.179-40.628-7.029-75.868-29.976-99.046-62.167zm117.966 57.991c-16.754-12.269-36.477-31.838-51.588-57.991h51.588v57.991zm0-72.991h-59.372c-8.934-19.363-15.104-41.7-16.14-66.79h75.511l.001 66.79zm0-81.789H86.505c.934-23.32 6.239-45.666 15.834-66.79h59.678v66.79zm0-81.79h-52.026a197.713 197.713 0 0 1 8.276-13.32c15.442-22.78 32.369-37.697 43.75-46.15v59.47zm161.861 81.79h-56.341c-.886-22.957-5.84-45.497-14.594-66.79h52.461c10.872 20.02 17.44 42.7 18.474 66.79zm-27.774-81.79H246.04a205.087 205.087 0 0 0-12.856-21.737c-10.583-15.612-23.76-30.273-37.521-42.057 41.405 7.088 77.235 30.702 100.441 63.794zM177.017 21.546c11.381 8.453 28.308 23.37 43.75 46.15a197.713 197.713 0 0 1 8.276 13.32h-52.026v-59.47zm0 74.471h59.678c9.595 21.124 14.9 43.47 15.834 66.79h-75.512v-66.79zm0 81.79h75.511c-1.035 25.09-7.206 47.427-16.14 66.79h-59.372l.001-66.79zm0 81.789h51.588c-15.111 26.152-34.834 45.722-51.588 57.991v-57.991zm18.922 62.167c11.327-9.54 24.738-23.038 37.215-41.178a205.148 205.148 0 0 0 12.671-20.988h49.157c-23.177 32.189-58.416 55.136-99.043 62.166zm108.584-77.167H252.9c8.875-21.139 13.78-43.472 14.643-66.79h56.265a153.405 153.405 0 0 1-19.285 66.79z" />
    </Svg>
)

export default SvgComponent
